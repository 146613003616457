@import '../colors.scss';

#dialogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    h1 {
        flex: 1;
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
    .icon {
        height: 24px;
        width: 24px;
        margin-right: 15px;
    }
    .warningIcon {
        height: 40px;
        width: 40px;
        color: $orangey-yellow;
    }
    button {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }
}

#dialogContent {
    padding: 20px;
    p {
        margin: 0;
        padding: 0;
        font-size: 12px;
    }
}

#dialogFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    button {
        color: white;
        text-transform: uppercase;
        &.cancel {
            color: #222;
            background: none;
            padding: 0;
        }
    }
}
