.loading {
    pointer-events: none;
    opacity: 0.7;
}

.searchbuttoncontainer {
    width: min-content;
}

.searchcontainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    align-items: center;

    :global {
        .tagsplitter {
            display: none;
        }
    }

    .clearSearchContainer {
        width: max-content;

        .clearSearchButton {
            font-size: 12px;
            cursor: pointer;
            color: #2d9ff7;
            height: 51px;
            line-height: 51px;
            margin-right: 10px;
        }
    }


    .toggleFiltersButton {
        font-size: 12px;
        cursor: pointer;
        color: #2d9ff7;
        height: 51px;
        line-height: 51px;
        margin-right: 16px;
    }

    > div.control {
        box-sizing: border-box;
        height: 51px;
        width: 215px;
        margin: 0;
        padding: 0;
        margin-right: 10px;
        margin-top: 5px;

        &.daterange {
            height: 47px;
            margin-top: 0;
        }
        // > div {
        //     height: 100%;
        // }
        // :global {
        //     .listFilterOutlinedField > div {
        //         height: 100%;
        //     }
        // }

        &.multiselect {
            width: 215px;
            max-width: 225px;
        }

        :global {
            #multiselect-root {
                > div > div > div {
                    height: 48px;
                }
            }
        }
    }

    .advancedSearch {
        height: 51px;
        margin-top: 5px;
        margin-right: 10px;

        :global {
            .searchTextInputWrapper {
                height: 47px;

                img {
                    display: none;
                }

                div {
                    height: 51px;
                }
            }
        }
    }

    > div.multiselect {
        width: 225px;
        max-width: 225px;
    }

    // > * {
    //     margin-right: 10px;
    //     margin-top: 10px;
    //     box-sizing: border-box;
    //     width: 225px;
    //     &.advancedSearch {
    //         width: max-content;
    //         &.not-executed .searchTextInputWrapper {
    //             color: #6b7897;
    //             border-color: #6b7897;
    //         }
    //         .searchTextInputWrapper {
    //             width: 227px;
    //             .searchTextInput {
    //                 width: calc(200px - 12px - 20px);
    //             }
    //         }
    //         .tagContainer {
    //             display: none;
    //         }
    //     }
    //     &.drop-container, &.multiselect-container {
    //         height: 48px;
    //         width: inherit;
    //         & > * {
    //             margin-top: 0;
    //             margin-bottom: 0;
    //         }
    //     }

    // }
}
