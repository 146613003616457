@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.detailsTopSection {
    padding-top: 24px !important;
}

#personalDetails {
    padding: 24px;
    background-color: #f9f9f9;
    display: flex;
    flex-wrap: wrap;
    .savingOverlay {
        background-color: rgba(255,255,255,0.7);
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeIn 300ms ease-in-out;
    }
    .detailsSections {
        .detailsSection {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            :global {
                .dateWrapper {
                    height: 49px;
                    margin: 6px 0;
                }
            }
        }
    }
    .listContainer {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: 1;
        h3 {
            padding: 16px 24px;
            flex-shrink: 0;
        }
        > div {
            flex: 1;
            overflow: hidden;
        }
        :global {
            .dateWrapper {
                width: 100%;
                .inputWrapper {
                    input {
                        // Sorry if this was something important.
                        // width: 90% !important;
                    }
                }
            }
        }
    }
}

.timeTracking {
    display: flex;
    padding-top: 8px;

    div {
        flex: 1;
    }

    h3, p {
        padding: 16px 24px;
        margin: 0;
    }

    p {
        text-align: right;
        font-size: 12px;
        padding-right: 16px;

        > span:first-child {
            margin-right: 15px;
        }
    }
}
