.addActivitySlider {
    .activityTypeIcon {
        height: 16px;
        width: 16px;
        background-color: #f5f5f5;
        border-radius: 50%;
        padding: 4px;
        margin-right: 8px;
    }
    :global {
        .treeDropdownOutlinedField {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
