@import '../colors.scss';

.actionMenuButton {
    position: relative;
    margin-right: 0px;
    .menuContent {
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        .menu {
            background-color: white;
            padding: 20px 0;
            box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
            border-radius: 3px;
            :global {
                .menu-item {
                    font-size: 12px;
                    padding: 10px 24px;
                    padding-right: 60px;
                    white-space: nowrap;
                    overflow: hidden;
                    &:hover {
                        background-color: #eef7fe;
                    }
                    text-overflow: ellipsis;
                    color: $title;
                    cursor: pointer;
                    line-height: 20px;
                    img {
                        margin-top: -5px;
                        margin-right: 10px;
                        width: 22px;
                        vertical-align: middle;
                    }
                    svg {
                        margin-top: -5px;
                        margin-right: 10px;
                        height: 20px;
                        width: 22px;
                        vertical-align: middle;
                    }
                    path:not([fill]),
                    svg {
                        color: $subtitle;
                        fill: $subtitle;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
