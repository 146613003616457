@import '../colors.scss';

.optionsMenu {
    button {
        border: none;
        color: $dark-sky-blue;
        padding-right: 25px;
        padding-left: 8px;
        &:hover {
            border: none;
        }
        svg {
            fill: $dark-sky-blue;
            background-color: transparent;
        }
    }
}

#grow {
    li {
        font-size: 12px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-weight: bold;
        svg {
            color: $dark !important;
            width: 20px;
            height: 20px;
            margin-right: 4px;
            margin-left: -2px;
        }
    }
}
