@import "../../general";

.dropdownCell__dropdown {
	display: block;
	top: -10px;
	box-sizing: border-box;
	background: #fff;
	border-radius: 2px;
	color: #575962;
	font-size: 12px;
	max-height: 200px;
	box-shadow: 3px 3px 10px 3px #ddd;
	overflow-y: auto;
	overflow-x: hidden;
}

ul.dropdownCell__list {
	display: block;
	list-style: none;
	padding-left: 0;
	margin: 0;

	li {
        @include notSelectable;

		font-family: "Open Sans";
		// TODO: Move into the component's props.
		height: 28px;
		line-height: 28px;
		padding-left: 8px;
		padding-right: 8px;
		cursor: pointer;
		white-space: nowrap;


		&:hover {
			background: #ddd;
		}

        &.disabled {
            color: #ccc;
            cursor: not-allowed;
        }

	}
}

.dropdownCell__activeRow {
	background: #ddd;
}

.dropdownCell__wrapper {
	height: 100%;

	svg {
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;

		path {
			color: #ccc;
		}
	}
}
