@import '../colors.scss';

.userAvatarRow {
    display: flex;
    align-items: center;
    height: fit-content !important;
    width: fit-content;
    cursor: pointer;
}

.emptyAvatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #dadfea !important;
    color: $dark !important;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        color: $dark !important;
        fill: $dark !important;
        height: 16px;
        width: 16px;
        * {
            color: $dark !important;
            fill: $dark !important;
        }
    }
}

.userAvatar {
    background-color: #dadfea !important;
    color: $dark !important;
    margin-right: 8px;
    &.rest {
        background-color: transparent !important;
        border: 1px solid #dadfea;
        color: $dark !important;
    }
}
