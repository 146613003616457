@import '../colors.scss';

@keyframes fallIn {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0);
    }
}

#onboarding {
    flex: 1;
    height: 100vh;
    box-sizing: border-box;
    // min-height: -webkit-fill-available;
    overflow: hidden;
    width: 100vw;
    background-image: url('./img/onboarding-bg-blurred.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    .dialog {
        width: 500px;
        max-width: 100%;
        max-height: 100%;
        background-color: white;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 40px;
        text-align: center;
        box-sizing: border-box;
        overflow: auto;
        .stepIndicator {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16px;
            margin-top: -4px;
            animation: fallIn 200ms ease-in-out;
            .stepContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                &:first-of-type {
                    align-items: flex-start;
                }
                &:last-of-type {
                    align-items: flex-end;
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    p {
                        margin: 0;
                        padding: 0;
                        font-size: 10px;
                        margin-bottom: 8px;
                        color: $steel;
                        &.current {
                            font-weight: bold;
                            color: $dark;
                        }
                    }
                    .bottom {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        .progressLine {
                            flex: 1;
                            background-color: #eee;
                            height: 3px;
                            &.green {
                                background-color: $greenish-cyan;
                            }
                            &.blue {
                                background-color: $dark-sky-blue;
                            }
                        }
                        .stepNumber {
                            width: 26px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background-color: $dark-sky-blue;
                            color: white;
                            font-size: 10px;
                            margin: 0 4px;
                            &.clickable {
                                cursor: pointer;
                            }
                            svg {
                                color: white;
                                height: 12px;
                                width: 12px;
                            }
                            &.green {
                                background-color: $greenish-cyan;
                            }
                        }
                    }
                }
            }
        }
        .titleContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                background: none;
                height: unset;
                border: none;
                padding: 0;
                margin: 0;
                width: fit-content;
                display: inline;
                color: $dark-sky-blue;
                cursor: pointer;
                font-size: 12px;
            }
            h1,
            h2,
            p {
                margin: 0;
                padding: 0;
            }
            h1 {
                font-size: 22px;
            }
            p {
                font-weight: 500;
                font-size: 13px;
                color: $steel;
                margin-top: 4px;
            }
        }
        .fieldContainer {
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            .error {
                color: $faded_red;
                text-align: left;
                align-self: flex-start;
                margin: 0;
                padding: 0;
                font-size: 10px;
                position: absolute;
                left: 0;
                top: -12px;
                &.bottom {
                    top: unset;
                    bottom: 52px;
                }
            }
            .nextStepButtonContainer {
                width: 100%;
            }
            .halfFieldContainer {
                width: 100%;
                display: flex;
                > div {
                    flex: 1;
                    &:first-of-type {
                        margin-right: 12px;
                    }
                }
            }
            :global {
                .datalist-container {
                    width: 100%;
                }
            }
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                border: none;
                background: none;
                cursor: pointer;
                color: white;
                background-color: $dark-sky-blue;
                width: 100%;
                padding: 12px 16px;
                box-sizing: border-box;
                text-transform: uppercase;
                font-weight: bold;
                margin-top: 24px;
                height: 46px;
                font-family: 'Lato', sans-serif;
                font-size: 12px;
                > * {
                    pointer-events: none;
                }
                &:hover {
                    opacity: 0.6;
                }
                &.googleButton {
                    border: 1px solid $border;
                    background: none;
                    margin-top: 16px;
                    padding: 8px 16px;
                    color: $dark;
                    width: fit-content;
                    text-transform: none;
                    &:hover {
                        opacity: 1;
                        background: #f9f9f9;
                    }
                    h2 {
                        font-size: 12px;
                    }
                    > img {
                        margin-right: 8px;
                        height: 24px;
                        width: 24px;
                        object-fit: contain;
                    }
                }
            }
            .dividerContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;
                margin-top: 16px;
                p {
                    font-weight: 500;
                    font-size: 12px;
                    color: $steel;
                    margin: 0 16px;
                }
                .line {
                    height: 1px;
                    flex: 1;
                    background-color: $steel;
                }
            }
            .firstContinueButton {
                height: unset;
                margin-top: 8px;
            }
            .addInviteeButton {
                padding: 0;
                margin: 0;
                margin-top: 6px;
                margin-bottom: 4px;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: $dark-sky-blue;
                width: fit-content;
                align-self: flex-start;
                font-weight: bold;
                font-size: 12px;
                cursor: pointer;
                &:hover {
                    opacity: 0.6;
                }
                svg {
                    color: $dark-sky-blue;
                    margin-right: 4px;
                    width: 14px;
                    height: 14px;
                }
            }
            .invitee {
                width: 100%;
                position: relative;
                margin-top: 20px;
                .removeInviteeButton {
                    position: absolute;
                    background: none;
                    border: none;
                    margin: 0;
                    padding: 0;
                    top: -28px;
                    right: -4px;
                    width: 20px;
                    cursor: pointer;
                    outline: none;
                    &:hover {
                        opacity: 0.6;
                    }
                    svg {
                        margin-top: -1px;
                        height: 17px;
                        width: 17px;
                    }
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .footerTexts {
            margin-top: 24px;
            p {
                font-weight: normal;
                font-size: 12px;
                color: $steel;
                margin: 0 16px;
                margin-bottom: 8px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #onboarding {
        padding: 0;
        position: relative;
        .dialog {
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: unset;
            max-width: unset;
            height: 100%;
            height: -webkit-fill-available;
            border-radius: 0;
        }
    }
}

@media screen and (max-width: 500px) {
    #onboarding {
        .dialog {
            padding: 28px 16px;
        }
    }
}

@media screen and (max-width: 400px) {
    #onboarding {
        .dialog {
            padding: 28px 12px;
            .stepIndicator {
                .stepContainer {
                    > div {
                        > p {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {
    #onboarding {
        .dialog {
            padding: 24px 12px;
            .titleContainer {
                h1 {
                    font-size: 18px;
                }
                p {
                    margin-top: 2px;
                    font-size: 12px;
                }
                button {
                    font-size: 12px;
                }
            }
            .fieldContainer {
                margin-top: 16px;
                .error {
                    position: relative;
                    top: unset;
                    bottom: unset !important;
                    &.bottom {
                        margin-top: 8px;
                        margin-bottom: -16px;
                    }
                }
            }
        }
    }
}
