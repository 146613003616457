@import '../colors.scss';

.actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px !important;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    &:hover {
        background-color: #eee;
    }
    .withInfoLabel {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        p {
            margin: 0;
            padding: 0;
            font-size: 9px;
            color: #6b7897;
            margin-bottom: -3px;
            margin-top: 2px;
            line-height: 1;
        }
    }
    svg {
        height: 20px;
        width: 20px;
        color: $subtitle !important;
        fill: $subtitle !important;
    }
    &.active {
        svg {
            color: $dark-sky-blue !important;
            fill: $dark-sky-blue !important;
        }
    }
}

.notificationBadge {
    span {
        min-width: 16px;
        height: 16px;
        font-size: 9px;
        margin-right: 2px;
        margin-top: 2px;
        color: #ffffff;
        background-color: #f7548f;
    }
}

.separator {
    height: 30px;
    width: 1px;
    background-color: $border;
    margin-right: 12px;
    margin-left: 4px;
}

.addNewMenu {
    margin: 0;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    color: $subtitle;
    border-radius: 5px;
    padding: 4px;
    &:hover {
        background-color: #eee;
    }
    &:after {
        margin-left: 8px !important;
        margin-bottom: 2px;
        border-color: $subtitle;
    }
}
