$total-left-row-color: #f9f9f9;
$total-right-row-color: #d7d7df;
$gantt-selected-color: #2d9ff720;
$gantt-totals-row-height: 30px;

.root {
    // display: flex;
    flex: 1;
    min-height: min-content;

    .gantt {
		flex: 1;
        overflow: hidden;
        position: absolute;

		:global(*) {
			font-family: 'Open Sans', sans-serif;
		}

        .inner {
			min-height: min-content;
        }
    }

    .totalRow {
        background: #F7F8FC;
        display: flex;
        font-weight: 600;
        font-size: 11px;
        height: $gantt-totals-row-height;
        align-items: center;
    
        div {
            box-sizing: border-box;
            padding: 6px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: right;
            padding-left: 6px;
            white-space: nowrap;
    
            // Total text
            &.GanttTotal-text {
                text-align: left;
            }
        }
    }
}