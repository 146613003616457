@import '../../colors.scss';

.materialDialog {
    .paper {
        width: 100%;
        height: 100%;
        background-color: white;
        max-width: 100% !important;
        .materialDialogHeader {
            height: 80px;
            display: flex;
            align-items: center;
            flex-shrink: 0;
            padding: 0 16px;
            h1 {
                padding: 0;
                margin: 0;
                font-size: 18px;
            }
        }
        .materialDialogFooter {
            height: 120px;
            flex-shrink: 0;
            padding: 0 16px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2 {
                padding: 0;
                margin: 0;
                font-size: 14px;
                margin-bottom: 10px;
            }
            > div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .materialDialogFilters {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    overflow-x: auto;
                    :global {
                        .dateWrapper {
                            height: 48px !important;
                            margin-right: 12px;
                            width: 160px;
                            flex-shrink: 0;
                        }
                        .listFilterOutlinedField {
                            height: 100%;
                            flex-shrink: 0;
                            width: 200px;
                            margin-right: 12px;
                            margin-top: 4px;
                            > div {
                                height: 100%;
                            }
                        }
                    }
                    .materialDialogAttachmentSettings {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex-shrink: 0;
                        margin-left: 8px;
                        > div {
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            font-size: 12px;
                            > span {
                                padding: 0;
                                margin-right: 8px;
                                > svg {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
                .materialDialogButtons {
                    display: flex;
                    align-items: center;
                    flex-shrink: 0;
                    margin-left: 8px;
                    .optionsMenu {
                        display: inline-block;
                        margin-left: 8px;
                        .optionsMenuButton {
                            background: $dark-sky-blue;
                            border: none;
                            color: white;
                            padding: 7px 34px 8px 16px;
                            svg {
                                fill: white;
                                background: $dark-sky-blue;
                            }
                        }
                        :global {
                            #menu-list-grow {
                                svg {
                                    margin-right: 10px;
                                    margin-top: 2px;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                    > button {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
    :global {
        .mainListOuterWrapper {
            .mainListWrapper {
                .mainList {
                    .contents {
                        min-height: calc(100% - 44px);
                        .inner {
                            > svg {
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                    .massInvoicingSummaryRow {
                        position: sticky !important;
                        bottom: 0;
                        background-color: $list-header-bg !important;
                        .cellValue {
                            font-weight: bold !important;
                        }
                        .overflow-visible {
                            .cellValue {
                                overflow: visible;
                            }
                        }
                    }
                }
            }
        }
    }
}

.menuItemAction {
    margin-right: 0 !important;
}

#massInvoicingList {
    .filterContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        z-index: 2;
        position: relative;
        .menuItemAction {
            display: none;
        }
        .saveSearchButton {
            background: none;
            color: $dark-sky-blue;
            font-weight: normal;
            font-size: 12px;
            svg {
                color: $dark-sky-blue;
                height: 18px;
                width: 18px;
                margin-right: 4px;
            }
        }
        .multiselect {
            width: 200px;
            max-width: 200px;
            height: 49px !important;
        }
        :global {
            .advancedSearch {
                flex-shrink: 0;
            }
            .tagsplitter {
                flex-shrink: 0;
            }
            .listFilterOutlinedField {
                height: 100%;
                width: 200px !important;
                max-width: 200px;
                > div {
                    height: 100%;
                }
            }
        }
        .dateRange {
            width: 220px;
            margin-right: 10px;
            height: 49px;
        }
        div[id^='select-'] {
            padding-top: 25px;
        }
    }
    :global {
        .mainListOuterWrapper {
            .mainListWrapper {
                .mainList {
                    .contents {
                        min-height: calc(100% - 44px);
                    }
                    .massInvoicingSummaryRow {
                        position: sticky !important;
                        bottom: 0;
                        background-color: $list-header-bg !important;
                        .cellValue {
                            font-weight: bold !important;
                        }
                        .overflow-visible {
                            .cellValue {
                                overflow: visible;
                            }
                        }
                    }
                }
            }
        }
    }
}
