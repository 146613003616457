.root {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    :global(.popup-container) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .left-row-plus {
        margin-right: 15px;
        height: 25px;
        width: 25px;
        color: #50e3c2;

        &:hover {
            opacity: 0.5;
        }
    }

    .hover-button-container {
        display: flex;
        padding-right: 15px;
        padding-left: 25px;
        align-items: center;
        justify-content: flex-end;
        overflow: visible;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;

        &.hover-shifted {
            right: 25px;
        }

        span {
            margin-left: 12px;

            &:first-child {
                margin-left: 0;
            }
        }

        .hover-button {
            // height: 20px;
            width: 20px;
            color: #6b7897;
            display: block;

            &:hover {
                color: #2d9ff7;
                transform: scale(1.2);
                cursor: pointer;
            }

            &.plus {
                color: #50e3c2;
                fill: currentColor;
            }
        }
    }

    h3,
    p {
        margin: 0;
        padding: 0;
        line-height: normal;
    }

    .tabbutton {
        border-right: 1px solid #dde3e8;
        background-color: white;

        &.selected {
            background-color: #2d9ff7;
            color: #ffffff;
        }

        border-top: 1px solid #dde3e8;
        border-bottom: 1px solid #dde3e8;

        &:first-child {
            border-left: 1px solid #dde3e8;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-right: 1px solid #dde3e8;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}