@import '../common.scss';

.root {
    background-color: $light-gray;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px 6px 10px;
    background-color: $light-gray;

    :global(.button-menu-container) {
        position: absolute;
        // top: 40px;
        // padding-top: 10px;
        left: 0;
        z-index: 999;
        transition: opacity 0.2s ease-in-out;
    }

    :global(.button-container) {
        position: relative;

        :global(.button-icon) {
            height: 20px;
            width: 20px;
            font-size: 20px;
            line-height: 20px;
            color: $main-blue;
            font-weight: bold;
            fill: $main-blue;
            margin-right: 7px;
        }

        

        :global(.header-button) {
            display: flex;
            align-items: center;
            background-color: transparent;
            font-weight: bold;
            min-width: 0;
            padding: 10px;
            margin: 0;
            margin-left: 10px;
            color: $main-blue;
            white-space: nowrap;

            &:hover {
                background-color: #d8d8d8;
            }

            p {
                margin: 0;
            }

            :global(.dropdown-icon) {
                color: $main-blue;
                padding: 0;
                width: 20px;
                margin-left: 2px;
                margin-right: -5px;
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        white-space: nowrap;

        > div {
            margin-right: 5px;
        }

        .hideColumnSelector {
            cursor: pointer;
            font-size: 12px;
            color: #2d9ff7;
            font-weight: bold;
            display: flex;
            align-items: center;
            margin-left: 5px;
            box-sizing: border-box;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 4px;
            padding: 0 4px;
            height: 44px;

            svg {
                width: 20px;
                height: 20px;
                color: inherit;
                fill: currentColor;
                margin-right: 4px;
            }

            &:hover {
                background-color: #d8d8d8;
            }
        }
    }
}

.text {
    margin-right: 8px;
}