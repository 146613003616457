$videoWidth: 1000px;

#embedVideoOverlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    button {
        position: absolute;
        right: 20px;
        top: 20px;
        background: none;
        color: white;
        svg {
            color: white;
        }
    }
    iframe {
        width: $videoWidth;
        max-width: 80%;
        height: calc(0.5625*#{$videoWidth});
    }
}