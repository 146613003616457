.twocol {
    grid-column: span 2;
}

$border: 1px solid #B2B9C9;

.borderLeft {
    border-left: $border;
}

.borderRight {
    border-right: $border;
}

.center {
    justify-content: center;
}
.right {
    justify-content: flex-end;
}