@import "../../../common.scss";

.TaskImageContainer {
    margin-right: 4px;
    display: flex;
    align-items: center;

    svg {
        width: 13px;
    }
}

.gridRowLeftNames {
    overflow: hidden;

    h3.grid-type-project {
        color: $main-blue;
        cursor: pointer;
    }
}
