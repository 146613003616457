@import '../../colors.scss';

.customOptionButton {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    margin-right: -2px;
    svg {
        height: 20px;
        width: 20px;
        fill: $carnation-pink;
    }
    &:hover {
        opacity: 0.6;
    }
}

.hourSelector {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    margin-right: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    .description {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 120px;
        margin-top: 12px;
        p,
        textarea {
            margin: 0;
            padding: 0;
        }
        p {
            margin: 8px 0;
        }
        textarea {
            flex: 1;
            border-radius: 5px;
            border: 1px solid #eee;
            resize: none;
            outline: none;
            padding: 8px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
        }
    }
    .hours {
        height: 150px !important;
        max-height: 150px;
        display: flex;
        flex: 1;
        overflow: hidden;
        align-items: stretch;
        > div {
            min-width: 70px;
            &:nth-child(2) {
                min-width: 100px;
            }
            white-space: nowrap !important;
            font-size: 12px;
            height: auto;
            ul {
                padding-top: 4px;
                padding-bottom: 4px;
                > div {
                    padding: 8px 16px;
                }
            }
            :global {
                .selectedScrollListItem {
                    background-color: #eef7fe;
                }
            }
        }
    }
}
:global {
    .time-tracker-block {
        :local {
            #bulkEntry {
                height: 550px;
                .listContainer {
                    height: 100%;
                    overflow: hidden;
                }
            }
        }
    }
}
.error {
    color: $carnation-pink;
    background-color: rgba($color: $carnation-pink, $alpha: 0.1);
    border: 1px solid $carnation-pink !important;
    &::placeholder {
        color: $carnation-pink;
    }
}
#bulkEntry {
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 106px - 102px);
    overflow: visible;
    display: flex;
    flex-direction: column;
    button,
    input {
        outline: none !important;
    }
    :global {
        .bulkEntryFavoriteCell {
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            button {
                border: none;
                background: none;
                cursor: pointer;
                &:hover {
                    opacity: 0.6;
                }
                svg {
                    color: #dde3e8;
                }
            }
            &.selected {
                button {
                    svg {
                        color: #feab1c;
                    }
                }
            }
        }
    }
    .textButton {
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        background: none;
        border: none;
        font-size: 12px;
        color: $dark-sky-blue;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
        svg {
            fill: $dark-sky-blue;
            margin-right: 6px;
            height: 20px;
            width: 20px;
        }
        p {
            margin: 0;
            padding: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }
        &:hover {
            opacity: 0.6;
        }
    }
    .topSection {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 0 20px;
        margin: 8px 0 16px;
        flex-shrink: 0;
        :global {
            .treeDropdownOutlinedField {
                min-width: 200px;
                max-width: 200px;
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
        }
        .templateDropdown {
            width: 200px;
            box-sizing: border-box;
            margin-left: 4px;
            :global {
                .textfield {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
            }
        }
        .editProjects {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-right: 15px;
            > img {
                height: 40px;
                width: 40px;
            }
            .top {
                display: flex;
                flex-direction: row;
                align-items: center;
                button {
                    padding: 1px 0;
                    margin-left: 15px;
                }
            }
            :global {
                .tagsplitter {
                    display: none;
                }
                .advancedSearch {
                    margin-right: 8px;
                    .searchTextInputWrapper {
                        background-color: white;
                    }
                }
                .insight-drop-down {
                    > button {
                        padding: 0;
                        margin-top: 8px;
                        background-color: transparent !important;
                        svg {
                            margin-left: 0px;
                        }
                    }
                }
            }
            .bottom {
                display: flex;
                align-items: center;
                margin-top: 8px;
                button {
                    padding: 0;
                    margin-right: 15px;
                    &.red {
                        color: $carnation-pink;
                    }
                }
            }
        }
        .weekSelector {
            display: flex;
            align-items: center;
            h2 {
                margin: 0;
                padding: 0;
                font-size: 15px;
                line-height: 1;
                white-space: nowrap;
            }
            button {
                background: none;
                border: none;
                cursor: pointer;
                &:hover {
                    opacity: 0.6;
                }
                svg {
                    color: $dark-sky-blue;
                    margin-top: 1px;
                }
            }
        }
    }
    .emptyColumn {
        width: 60px;
        flex-shrink: 0;
        height: 100%;
    }
    .column {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 6px;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;
        &.end {
            align-items: flex-end;
            text-align: right;
            overflow: hidden;
        }
        h3,
        p {
            margin: 0;
            padding: 0;
            font-size: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }
        h3 {
            color: $title;
        }
        p {
            color: $subtitle;
            white-space: nowrap;
        }
        &.holiday {
            h3,
            p {
                color: $carnation-pink;
            }
            font-weight: bold;
        }
        &.selected {
            h3,
            p {
                color: $dark-sky-blue;
            }
            font-weight: bold;
        }
    }
    .mainContent {
        display: flex;
        justify-content: stretch;
        width: 100%;
        overflow: hidden;
        flex: 1;
        position: relative;
        .noDataOverlay {
            position: absolute;
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.8);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1 {
                margin: 0;
                padding: 0;
                font-size: 20px;
            }
            p {
                padding: 0;
                margin: 5px 0 0;
            }
            img {
                margin-bottom: 30px;
            }
        }
        .listContainer {
            :global {
                .mainList {
                    scrollbar-width: none !important;
                    -ms-overflow-style: none !important;
                    &::-webkit-scrollbar {
                        width: 0px !important;
                        background: transparent !important;
                    }
                }
                .delete {
                    color: $title !important;
                    svg {
                        color: $carnation-pink !important;
                        fill: $carnation-pink !important;
                    }
                }
            }
        }
        .entryContainer {
            overflow: visible;
            flex-shrink: 0;
            background-color: white;
            border-left: 1px solid #f4f5f8;
            font-size: 12px;
            color: $title;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            .header {
                height: 48px;
                padding: 0 6px;
                background-color: white;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #f4f5f8;
                box-sizing: border-box;
                position: sticky;
                top: 0;
                flex-shrink: 0;
            }
            .rows {
                overflow: auto;
                flex: 1;
                .row {
                    height: 44px;
                    box-sizing: border-box;
                    padding: 0 6px;
                    display: flex;
                    align-items: center;
                    &:nth-child(even) {
                        background-color: #f4f5f8;
                    }
                    .box {
                        background-color: white;
                        border: 1px solid #f4f5f8;
                        border-radius: 2px;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        input {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            outline: none;
                            border: none;
                            background: none;
                            font-size: 11px;
                            font-family: 'Open Sans', sans-serif;
                            &:disabled {
                                background-color: #dde3e8;
                                cursor: not-allowed;
                            }
                        }
                        &.holiday {
                            background-color: rgba(245, 148, 202, 0.15);
                        }
                        &.selected {
                            border: 2px solid $dark-sky-blue;
                        }
                    }
                }
            }
        }
    }
    .footer {
        background-color: #f4f5f8;
        width: 100%;
        box-sizing: border-box;
        height: 80px;
        display: flex;
        align-items: center;
        line-height: 1.7;
        .left {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            padding: 0 16px;
            font-size: 12px;
            overflow: hidden;
            flex-shrink: 1;
            hr {
                height: 20px;
                width: 1px;
                border: none;
                background-color: #dde3e8;
                margin: 0 15px;
                padding: 0;
            }
            button {
                padding: 0;
                margin: 0;
            }
        }
        .right {
            padding: 0 6px;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            flex-shrink: 0;
        }
    }
}

input[name="hourEntryInput"] {
    &.declined {
        border: 1px solid red !important;
    }
}