.confirmation-popup {
    width: 80%;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    padding: 10px 0;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    .confirmation-header {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      height: 80px;
      border-bottom: 1px solid #f9f9f9;
      padding: 0 30px;
      font-weight: bold;
      font-size: 18px;
      .icon {
        width: 50px;
        height: 50px;
        margin-right: 15px;
        color: #ffcf5c;
      }
    }
    .confirmation-desc {
      padding: 30px;
    }
    .confirmation-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 0 30px;
      padding-bottom: 20px;
      .button-container {
        display: flex;
        align-items: center;
      }
      .confirmation-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 12px;
        border-radius: 5px;
        padding: 15px 30px;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .confirmation-popup .confirmation-buttons {
      align-items: flex-end;
      .button-container {
        flex-direction: column;
        align-items: flex-end;
        .confirmation-button {
          margin-right: 0;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }