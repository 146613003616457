@import '../../colors.scss';

.toggleVisibilityButton {
    border: none;
    background: none;
    width: fit-content;
    min-width: 0;
    padding: 0;
    margin: 0;
    color: $dark-sky-blue;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    margin-top: 6px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
        opacity: 0.6;
    }
}

.actionButtons {
    display: flex;
    align-items: center;
    margin-left: 4px;
    .optionsMenu {
        > button {
            border: none;
            padding: 5px 16px;
            padding-right: 32px;
            background-color: #dde3e8;
            color: #34495e;
            font-size: 12px;
            white-space: nowrap;
            svg {
                fill: #34495e;
                background: #dde3e8;
            }
        }
        &.addNew {
            > button {
                background-color: $greenish-cyan;
                color: white;
                svg {
                    fill: white;
                    background: $greenish-cyan;
                }
            }
        }
        :global {
            #menu-list-grow {
                margin-top: 10px;
                :local {
                    .delete {
                        color: $faded-red !important;
                        > svg {
                            color: $faded-red !important;
                            fill: $faded-red !important;
                        }
                    }
                }
                svg {
                    margin-right: 10px;
                    margin-top: 2px;
                    width: 14px;
                    height: 14px;
                    color: $steel;
                    fill: $steel;
                }
            }
        }
    }
}

.sectionAddButton {
    border: none;
    background: none;
    width: fit-content;
    min-width: 0;
    padding: 0;
    margin: 0;
    color: $dark-sky-blue;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    &:hover {
        opacity: 0.6;
    }
}

.detailsBox {
    width: 450px;
    background-color: white;
    height: calc(100vh - 160px);
    overflow: auto;
    box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
    border-radius: 6px;
    box-sizing: border-box;
    padding: 24px;
    padding-top: 0;
    margin-right: 24px;
    &.withDoubleTabs {
        height: calc(100vh - 200px);
    }
    &.fullWidth {
        width: 100%;
        margin-right: 0;
    }
    .detailsTopSection {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        border-bottom: 1px solid #eee;
        .topRow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            .row {
                display: flex;
                align-items: center;
            }
            .buttons {
                display: flex;
                align-items: center;
                margin-left: 16px;
                > button {
                    background: none;
                    border: none;
                    color: $dark-sky-blue;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: bold;
                    font-size: 12px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding-right: 0;
                    margin-left: 8px;
                    svg {
                        color: $dark-sky-blue;
                        margin-right: 6px;
                        margin-bottom: 4px;
                    }
                }
            }
            :global {
                #menu-list-grow {
                    li {
                        font-size: 12px;
                        svg {
                            margin-right: 10px;
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
                .context-menu {
                    min-width: 0;
                    margin-right: -8px;
                    padding: 0;
                }
            }
            .tags {
                display: flex;
                align-items: center;
                :global {
                    .status-tag {
                        margin-right: 6px;
                        padding: 6px 8px;
                        font-size: 10px;
                        min-width: 60px;
                    }
                }
            }
        }
        .titleRow {
            display: flex;
            align-items: center;
            :global {
                .avatar {
                    margin-right: 16px;
                    font-size: 24px;
                    height: 55px !important;
                    width: 55px !important;
                }
            }
        }
        .titles {
            display: flex;
            flex-direction: column;
            &.noTopMargin {
                margin-top: 0px;
            }
            > div {
                display: flex;
                align-items: center;
                margin-top: 4px;
                p {
                    font-weight: normal;
                    font-style: italic;
                    font-size: 12px;
                }
                svg {
                    color: $orangey-yellow;
                    height: 18px;
                    width: 18px;
                    margin-right: 6px;
                }
            }
            h1 {
                margin: 0;
                padding: 0;
                font-size: 17px;
            }
            p {
                margin: 0;
                padding: 0;
                font-size: 13px;
                font-weight: bold;
                color: $steel;
            }
            a {
                font-size: 12px;
                font-weight: bold;
            }
        }
        .keyInfo {
            display: flex;
            flex-direction: column;
            margin-top: 14px;
            &.iconSection {
                margin-top: 16px;
                // margin-left: 70px;
                border-radius: 10px;
                // background-color: #fce8c781;
                // background-color: $white-two;
            }
            > div {
                height: 21px;
                display: flex;
                align-items: center;
                &.iconRow {
                    height: unset;
                    margin-bottom: 8px;
                    overflow: hidden;
                    button {
                        border: none;
                        background: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        margin: 0;
                        color: $dark-sky-blue;
                        margin-left: 6px;
                        cursor: pointer;
                        svg {
                            color: $dark-sky-blue;
                            width: 16px;
                            height: 16px;
                        }
                    }
                    p {
                        font-weight: bold;
                        margin-left: 4px;
                        white-space: unset;
                        flex: 1;
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                .icon {
                    // height: 30px;
                    // width: 30px;
                    // background-color: $orangey-yellow;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 8px;
                    flex-shrink: 0;
                    svg {
                        width: 20px;
                        height: 20px;
                        // color: $orangey-yellow;
                        color: $steel;
                    }
                }
                a {
                    font-weight: bold;
                    margin-left: 4px;
                    font-size: 12px;
                    line-height: 1.3;
                }
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                    line-height: 1.3;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex-shrink: 0;
                    &:nth-of-type(2) {
                        flex-shrink: unset;
                        font-weight: bold;
                        margin-left: 4px;
                    }
                }
                :global {
                    .dateWrapper {
                        height: unset;
                        input {
                            font-weight: bold;
                            &::placeholder {
                                color: $dark-sky-blue;
                            }
                        }
                        svg {
                            color: $dark-sky-blue;
                            margin-left: 2px;
                        }
                    }
                    .seller > div {
                        margin: 0;
                        margin-left: 4px;
                        display: flex;
                        align-items: center;
                        .gura__menu {
                            min-width: max-content !important;
                        }
                        & > div {
                            border: 0;
                        }
                        span {
                            padding: 0 !important;
                        }
                        svg {
                            margin-right: -22px;
                            background: none;
                            fill: $dark-sky-blue;
                        }
                        p,
                        label {
                            font-size: 12px;
                            color: #2d9ff7;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                    .gura--is-disabled {
                        * {
                            background: transparent !important;
                            p {
                                color: $dark !important;
                                -webkit-text-fill-color: $dark !important;
                            }
                        }
                        svg {
                            display: none !important;
                        }
                    }
                }
                display: flex;
                align-items: center;
            }
        }
        .toggleVisibilityButton {
            margin-top: 0px !important;
        }
        .lastField {
            margin-bottom: 8px;
        }
        .stageIndicators {
            display: flex;
            align-items: center;
            margin-top: 11px;
            margin-bottom: 14px;
            height: 18px;
            width: 100%;
            max-width: 500px;
            > span {
                height: 100%;
                width: 100%;
            }
            .stageIndicator {
                flex: 1;
                height: 100%;
                box-sizing: border-box;
                background-color: #eee;
                border-radius: 3px;
                margin-right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 10px;
                cursor: pointer;
                &:last-of-type {
                    margin-right: 0;
                }
                &.done {
                    background-color: #2d9ff7;
                }
                &.lost {
                    background-color: #f7548f;
                }
                &.internal {
                    background-color: #6e41c0;
                }
                &.hold {
                    background-color: #feae0e;
                }
                &.won {
                    background-color: #4fe2c1;
                }
                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }
}

.peoplePreview {
    display: flex;
    align-items: center;
    height: fit-content !important;
    margin-top: 4px;
    hr {
        height: 50px;
        width: 1px;
        margin: 0 20px;
        background-color: #eee;
        border: none;
    }
}

.contactPreview {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    height: fit-content !important;
    flex: 1;
    overflow: hidden;
    p {
        margin-bottom: 8px !important;
    }
}

.contactAvatar {
    background-color: #dadfea !important;
    color: $dark !important;
    margin-right: 8px;
}

.contactSliderContainer {
    width: 440px;
    padding: 0 24px;
    margin-top: -16px;
    overflow: auto;
    flex: 1;
}

.contactPreviewLabels {
    p {
        margin: 0;
        padding: 2px 4px;
        &:first-of-type {
            margin-top: 2px;
        }
    }
    button {
        background: none;
        border: none;
        padding: 2px 4px;
        color: $dark-sky-blue;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        margin-bottom: 2px;
        cursor: pointer;
    }
}

.mainContent {
    flex: 1;
    background-color: white;
    height: calc(100vh - 160px);
    overflow: auto;
    box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.08);
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &.withDoubleTabs {
        height: calc(100vh - 200px);
    }
}

@media screen and (max-width: 1500px) {
    .detailsBox {
        width: 100%;
        min-width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
    }
    .mainContent {
        width: 100%;
        min-width: 100%;
    }
}
