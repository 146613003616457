@import '../../../common.scss';

.grid-slider-container {
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.2);
    transition: height 0.1s ease-in-out;
    display: flex;
    flex-direction: column;

    .grid-slider-padding {
        padding: 22px 10px 22px 0;
    }

    .grid-slider-row {
        display: flex;
        align-items: center;
        justify-content: center;

        &.centered {
            align-items: center;
        }

        &.multi-align {
            justify-content: space-between;
        }

        &.grid-slider-or {
            > div {
                width: 32px;
                border-top: 1px solid #b2b9c9;
            }

            > span {
                padding: 0 8px;
            }
        }

        .hour-entry-container {
            display: flex;
            align-items: center;
            margin-top: 20px;

            input {
                height: 40px;
                padding: 0;
                padding-left: 10px;
                font-weight: bold;
                font-size: 14px;
                color: $dark;
                width: 100px;
            }

            .clock-icon-container {
                width: 50px;
                margin-left: -5px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            button {
                height: 40px;
                background-color: #2d9ff7;
                color: white;
                margin-left: 10px;
                padding: 10px 20px;
                text-transform: uppercase;
            }
        }
    }

    .slider-container {
        width: 100%;
        max-width: 1000px;
        position: relative;
        box-sizing: border-box;
        padding: 0 50px;
        overflow-x: auto;

        .slider-handle {
            position: absolute;
            height: 25px;
            width: 25px;
            border-radius: 12.5px;
            background-color: white;
            z-index: 10;
            -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

            &:hover {
                cursor: pointer;
            }
        }

        .slider-labels {
            width: 100%;
            box-sizing: border-box;
            min-width: 700px;
            position: relative;
            display: flex;
            margin-top: 8px;
            margin-bottom: 20px;
            align-items: center;

            p {
                font-weight: bold;
            }

            .slider-label-container {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: flex-start;
            }
        }

        .slider {
            width: 100%;
            min-width: 700px;
            box-sizing: border-box;
            height: 16px;
            border-radius: 10px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            overflow: hidden;
            border: $slider-border;

            &:hover {
                cursor: pointer;
            }

            .slider-block {
                flex: 1;
                border-right: $slider-border;
            }
        }
    }
}

.grid-slider-modes-container {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 15px;

    .format-mode-button {
        display: flex;
        height: 25px;
        width: 40px;
        align-items: center;
        justify-content: center;
        color: #3f3356;
        font-size: 12px;
        font-weight: bold;

        &:hover {
            opacity: 0.5;
            cursor: pointer;
        }
    }

    .selected {
        background-color: #2d9ff7;
        color: white;
    }
}
