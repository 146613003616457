#notFound {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 70px);
    h3, p {
        margin: 0;
        padding: 0;
    }
    h3 {
        margin-top: 24px;
        margin-bottom: 4px;
    }
}
