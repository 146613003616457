@import './shared.scss';


.input {
    input {
        width: 50px;
        padding: 5px;
    }
}

.role {
    color: #999;
    padding-left: 4px;
}

div {
    &.borderLeft {
        padding-left: 28px !important;
    }

    &.borderRight {
        padding-right: 28px !important;
    }
}