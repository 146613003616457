@import '../colors.scss';

.companyField {
    height: 15px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    * {
        cursor: pointer;
    }
    .companyDatalist > div {
        margin: 0;
        display: flex;
        align-items: center;
        :global {
            .gura__menu {
                min-width: max-content !important;
            }
        }
        & > div {
            border: 0;
        }
        input {
            pointer-events: none;
        }
        span {
            padding: 0 !important;
        }
        svg {
            margin-right: -22px;
            background: none;
            fill: $dark-sky-blue;
        }
        p,
        label {
            font-size: 12px;
            color: $dark-sky-blue;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
