@import '../../colors.scss';

.addItem {
    display: flex;
    align-items: center;
    padding: 4px 16px;
    cursor: pointer;
    color: $dark;
    svg {
        color: $dark;
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
    &:hover {
        background-color: rgba(45, 159, 247, 0.06);
    }
}

.noOptionsItem {
    display: flex;
    align-items: center;
    padding: 4px 16px;
    color: $dark;
    svg {
        color: $dark;
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
}
