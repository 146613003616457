.users {
    width: 100%;
}

.grid {
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content min-content;
    font-size: 12px;
    align-items: center;
    margin: 8px;

    > div {
        display: contents;

        &.header, &.footer {
            font-weight: bold;
    
            .hours {
                text-align: center;
                padding-right: 10px;
            }
        }

        .twocol {
            grid-column: span 2;
        }
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .assignButtons {
        display: flex;

        div {
            margin-right: 8px;
        }
    }
}

.button {
    &.disabled {
        visibility: hidden;
    }

    svg {
        margin-right: 4px;
        height: 18px;
        color: #27abe2;
        fill: currentColor;
    }

    display: flex;
    justify-content: flex-end;
    align-content: center;
    cursor: pointer;
    color: #27abe2;
    font-size: 12px;
    grid-column: 2;
    text-align: right;
}
