.devOptions {
    width: 400px;
    max-width: 95vw;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    > button {
        margin-top: 10px;
        margin-bottom: 4px;
    }
}