// Project Card
$height-normal: calc(100vh - 151px);

// Full Page
$height-full: calc(100vh - 107px);

.loading {
    pointer-events: none;
    position: absolute;

    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px;
    z-index: 100;
    transition: opacity 0.1s;

    &.hidden {
        opacity: 0;
    }
}

.summarySection {
    margin-top: 16px;
    padding: 0;
    height: unset;
    .summaries {
        margin-left: 0;
    }
}

.root {
    display: grid;
    flex-direction: column;
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: 1fr;
    max-width: 100%;
    height: $height-normal;

    &.fullHeight {
        height: $height-full;
    }

    .header.listControlsContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 23px 20px 23px 20px;

        //background-color: white;
        .break {
            flex-basis: 100%;
            height: 0;
        }

        :global(.listFilterOutlinedField) {
            > div {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .add-task-button {
            margin-left: 24px;
            min-width: 160px;
            height: 47px;
        }

        .tabsDiv {
            display: flex;
            margin-bottom: 14px;

            .tabbutton {
                border-right: 1px solid #dde3e8;
                display: flex;
                color: inherit;
                min-width: initial;

                &.selected {
                    background-color: #2d9ff7;
                    color: #ffffff;
                }

                border-top: 1px solid #dde3e8;
                border-bottom: 1px solid #dde3e8;

                &:first-child {
                    border-left: 1px solid #dde3e8;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-child {
                    border-right: 1px solid #dde3e8;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }

    .content {
        // display: flex;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: hidden;
    }
}

.resourcing-options-dropdown {
    .export-dropdown {
        display: flex;
        flex-direction: column;
        margin: 6px 0;
        > button, > a {
            border: none;
            background-color: transparent;
            padding: 6px 16px;
            text-align: left;
            cursor: pointer;
            font-size: 12px;
            font-family: 'Open Sans', sans-serif;
            &:hover {
                background-color: rgba(44, 159, 247, 0.08);
            }
			color: black;

			svg {
				width: 20px;
				color: black;
				fill: currentColor;
				margin-right: 5px;
			}

			&:link {
				color: black !important;
			}
        }
		
    }
}