.add-tag {
    font-size: 12px;
    color: #333333;
    border-radius: 4px;
    border: 1px solid #e7eced;
    padding: 15px 10px;
    overflow-x: hidden;
    .primary {
        color: #2d9ff7;
    }
    &>.details {
        padding: 7px 0 11px 0;
    }
    &>.controls {
        text-align: right;
    }
}