
.tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 6px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    transform: translate(-1%, 0);
    z-index: 53563;
    
  }
  
  .chartkey {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50% 50%;
  }

.grouptitle {
    text-align: left;
    th {
        padding: 5px;
    }
}

.grouptitlerow {
    td {
        padding: 5px;

        // span[data-negative="1"] {
        //     color: #f7548f;
        // }

        // span[data-positive="1"] {
        //     color: #42b677;
        // }
    }
}