// @import "./general.scss";

.eyeMenu {
    height: 35px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #c3cbdb;
    margin-left: 15px;
    margin-right: 0 !important;

    > * {
        // pointer-events: none;
    }

    * {
        color: #2c3547;
        width: 20px;
        height: 20px;
        // pointer-events: none;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(45, 159, 247, 0.06);
    }

    &.disabled {
        opacity: 0.5;
        &:hover {
            cursor: not-allowed;
        }
    }
}

.muiList {
    z-index: 11;
    background: #fff;
	box-shadow: 0px 0px 16px #bababa;
    border-radius: 4px;
}

.muiListItem {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

    cursor: pointer;

    span {
        font-size: 12px;
        padding: 0;
    }

    &:hover {
        background: #eef8ff;
    }
}
