@import '../colors.scss';

$max-paper-width: 1100px;

#rowMenu {
    .delete {
        color: $faded-red;
        svg {
            color: $faded-red;
        }
    }
    li {
        display: flex;
        align-items: center;
        svg {
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }
}

// Had to change this to a class instead of an id (because the old print method needs a different id to function)
// and thus needed to set all the styles as important...............
.printContainer {
    font-family: 'Open Sans', sans-serif !important;
    * {
        font-family: 'Open Sans', sans-serif !important;
        -webkit-print-color-adjust: exact !important;
    }
    table {
        thead {
            display: table-header-group !important;
        }
        tfoot {
            display: table-footer-group !important;
        }
    }
    :global {
        .status-tag {
            display: none !important;
        }
        .header {
            position: relative !important;
            top: unset !important;
            padding: 0px 24px !important;
            * {
                font-size: 11px !important;
            }
        }
        .mainList {
            .list-row-hidden-from-print {
                display: none !important;
            }
            .row {
                padding: 6px 24px !important;
                height: fit-content !important;
                min-height: 40px !important;
                line-height: unset !important;
                align-items: center !important;
                .cell {
                    .inner {
                        * {
                            white-space: unset !important;
                            font-size: 11px !important;
                        }
                    }
                }
            }
            .contents {
                min-height: unset !important;
            }
        }
    }
    .quote {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        min-height: unset !important;
        .topSection {
            margin-top: -30px !important;
            .viewOptionsButton {
                display: none !important;
            }
            .names {
                h1 {
                    font-size: 16px !important;
                }
                input {
                    font-size: 16px !important;
                }
            }
            .addresses {
                * {
                    font-size: 11px !important;
                }
                h4 {
                    font-size: 12px !important;
                }
                :global {
                    .label-field {
                        height: 22px !important;
                        display: flex !important;
                        align-items: center !important;
                        &.quote-header-email-field {
                            .label {
                                line-height: 22px;
                            }
                            .cellValue {
                                margin-top: 3px;
                            }
                        }
                        .reactSelect {
                            height: 25px !important;
                            width: 100% !important;
                            .reactSelect__control {
                                height: 25px !important;
                                display: flex !important;
                                align-items: center !important;
                                width: 100% !important;
                                > div {
                                    &:nth-of-type(2) {
                                        display: none !important;
                                    }
                                }
                                .reactSelect__value-container {
                                    height: 100% !important;
                                    display: flex !important;
                                    align-items: center !important;
                                    flex-wrap: nowrap !important;
                                    width: 100% !important;
                                    > div {
                                        &:nth-of-type(2) {
                                            display: none !important;
                                        }
                                    }
                                    .reactSelect__single-value {
                                        position: relative !important;
                                        transform: none !important;
                                        top: unset !important;
                                        line-height: 1 !important;
                                        height: 25px !important;
                                        display: flex !important;
                                        align-items: center !important;
                                        width: 100% !important;
                                        max-width: unset !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .dates {
                    :global {
                        .labelFieldGroup {
                            margin-top: 44px !important;
                        }
                    }
                }
            }
        }
        .listContainer {
            margin-top: 0px !important;
            .list {
                .headerCell {
                    font-size: 12px !important;
                }
            }
        }
        .totalRow {
            &.hiddenFromPrint {
                display: none;
            }
            h2,
            h3 {
                font-size: 12px !important;
            }
            p {
                font-size: 10px !important;
            }
            .totalValue {
                height: 30px !important;
            }
        }
        .cellTextContainer {
            height: 27px !important;
            .editContainer {
                height: 100% !important;
                p {
                    line-height: unset !important;
                    &:nth-of-type(2) {
                        font-size: 10px !important;
                        margin-top: 2px !important;
                    }
                }
            }
        }
    }
    * {
        font-family: 'Open Sans', sans-serif !important;
    }
}

.editPopup {
    background-color: white;
    width: 200px;
    border-radius: 5px;
    padding: 16px;
    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    margin: 8px 0;
    > div {
        width: 100%;
    }
}

.quote {
    width: 100%;
    min-height: calc(#{$max-paper-width}* 1.414);
    box-sizing: border-box;
    background-color: white;
    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    table {
        width: 100%;
        table-layout: fixed;
        border: none;
        border-spacing: 0 !important;
        thead,
        tfoot {
            width: 100%;
            display: none;
            tr {
                height: 30px;
            }
        }
    }
    &.loading {
        opacity: 0.5;
        pointer-events: none;
    }
    .topSection {
        padding: 28px;
        padding-bottom: 22px;
        box-sizing: border-box;
        width: 100%;
        background-color: #f9f9f9;
        position: relative;
        transition: max-height 300ms ease-in-out;
        max-height: 1000px;
        position: relative;
        .tags {
            display: flex;
            align-items: center;
            margin-top: 10px;
            :global {
                .status-tag {
                    padding: 4px 12px;
                    min-width: 0;
                    margin-right: 8px;
                }
            }
        }
        &.shrinked {
            max-height: 250px;
        }
        .viewOptionsButton {
            position: absolute;
            bottom: 16px;
            right: 16px;
            border-radius: 4px;
            border: none;
            background-color: rgba(45, 159, 247, 0.2);
            display: flex;
            align-items: center;
            color: #2d9ff7;
            padding: 6px 10px;
            height: 36px;
            box-sizing: border-box;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 12px;
            line-height: 1;
            > * {
                pointer-events: none;
            }
            svg {
                height: 18px;
                width: 18px;
                color: #2d9ff7;
                margin-right: 5px;
                margin-left: 2px;
                &:last-of-type {
                    height: 16px;
                    width: 16px;
                    margin-right: 0;
                    margin-left: 2px;
                }
            }
        }
        .topSectionToggle {
            width: 40px;
            height: 40px;
            border: none;
            background-color: white;
            border-radius: 50%;
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            cursor: pointer;
            z-index: 10;
            display: none;
            &:hover {
                opacity: 0.6;
            }
        }
        .row {
            display: flex;
            width: 100%;
        }
        .addresses {
            width: 100%;
            display: flex;
            margin-top: 8px;
            :global {
                .label-field {
                    height: 25px !important;
                    display: flex;
                    align-items: center;
                    &.quote-header-email-field {
                        height: auto !important;
                        align-items: flex-start !important;
                        .cellValue {
                            font-size: 12px;
                            padding: 0 4px;
                            margin-top: 4px;
                            color: #34495e;
                            overflow-wrap: anywhere;
                            overflow: hidden;
                            white-space: normal;
                        }
                    }
                    .reactSelect {
                        height: 25px !important;
                        .reactSelect__control {
                            height: 25px !important;
                            display: flex;
                            align-items: center;
                            .reactSelect__value-container {
                                height: 100% !important;
                                display: flex;
                                align-items: center;
                                .reactSelect__single-value {
                                    line-height: 1 !important;
                                    height: 25px !important;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
            > div {
                flex: 1;
                margin-right: 16px;
                &.dates {
                    width: 190px;
                    margin-right: 0;
                    flex: unset;
                    flex-shrink: 0;
                    :global {
                        .dateWrapper {
                            height: unset;
                            .calendarView {
                                z-index: 99999;
                            }
                            input {
                                max-width: unset;
                                width: 100%;
                                box-sizing: border-box;
                            }
                            svg {
                                display: none;
                            }
                        }
                        .labelFieldGroup {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            margin-top: 56px;
                            width: 100%;
                        }
                    }
                }
                > div {
                    max-width: 300px;
                    width: 100%;
                    white-space: nowrap;
                }
            }
        }
        .names {
            flex: 1;
            margin-right: 16px;
            min-height: 85px;
            img {
                width: 80px;
                height: 80px;
            }
            .input {
                display: flex;
                align-items: center;
                width: 100%;
                h1 {
                    margin-left: 8px;
                }
            }
            input {
                border: none;
                flex: 1;
                font-size: 18px;
                color: $dark;
                font-weight: 600;
                background: none;
                outline: none;
                pointer-events: none;
                text-overflow: ellipsis;
                margin: 0;
                &.edit {
                    pointer-events: all;
                    border: 1px dashed #c3cbdb;
                    border-radius: 3px;
                    background: white;
                    padding: 4px 8px;
                }
            }
            .subtitle {
                margin-top: 0px;
                display: flex;
                align-items: center;
                h1 {
                    margin-right: 8px;
                    line-height: 1.2;
                    color: $steel;
                    font-size: 18px;
                    font-weight: 600;
                }
                input {
                    margin-right: 8px;
                    line-height: 1.2;
                    color: $steel;
                }
            }
        }
        .logoDates {
            width: 190px;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            .logoContainer {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                justify-self: flex-start;
                flex-shrink: 0;
                height: 120px;
                border-radius: 5px;
                box-sizing: border-box;
                background-color: transparent;
                p {
                    display: none;
                }
                p {
                    max-width: 200px;
                    box-sizing: border-box;
                    color: $steel;
                    padding: 2px 14px;
                }
                > div {
                    > div {
                        height: 100%;
                        overflow: hidden;
                    }
                }
                svg {
                    color: $steel;
                    margin-bottom: -32px;
                    margin-left: 6px;
                    height: 50px;
                    width: 50px;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    object-position: top right;
                }
                &.editMode {
                    background-color: #eee;
                    cursor: pointer;
                    p {
                        display: unset;
                    }
                    :global {
                        .invoice-logo {
                            display: unset;
                        }
                    }
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
        h1,
        h2 {
            margin: 0;
            padding: 0;
        }
        h1 {
            font-size: 20px;
        }
        h2 {
            font-size: 18px;
            color: $steel;
        }
    }
}

.listContainer {
    margin-top: 6px;
    .totalRow {
        position: relative;
        background-color: #f4f5f8 !important;
        margin-top: 16px;
        padding: 16px 24px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        page-break-inside: avoid !important;
        height: fit-content;
        h2,
        h3,
        p {
            padding: 0;
            margin: 0;
            font-size: 12px;
        }
        h2 {
            font-size: 16px;
            font-weight: 600;
        }
        h3 {
            font-size: 14px;
            font-weight: 600;
        }
        .totalValues {
            display: flex;
            align-items: flex-start;
            max-width: 600px;
            margin-left: 32px;
            > div {
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
                margin-left: 32px;
                text-align: right;
                .totalValue {
                    height: 38px;
                }
                .vatTotals {
                    display: flex;
                    flex-direction: column;
                    hr {
                        width: 100%;
                        height: 1px;
                        border: none;
                        background-color: $steel;
                        opacity: 0.5;
                        margin-block-start: 8px;
                        margin-block-end: 8px;
                    }
                    .vatTotalRow {
                        font-style: italic;
                        font-size: 11px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        p {
                            text-align: right;
                            &:first-of-type {
                                width: 50px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
                p {
                    white-space: nowrap;
                }
            }
        }
    }
    .rowIndicators {
        display: flex;
        align-items: center;
        position: absolute;
        left: -32px;
        top: 0;
        bottom: 0;
        > div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: 1px solid #ddd;
            border-radius: 50%;
            height: 24px;
            width: 24px;
            -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            svg {
                height: 16px;
                width: 16px;
                color: $steel;
                fill: $steel;
            }
        }
        &.multiple {
            left: -61px;
            > div {
                &:nth-of-type(2) {
                    position: absolute;
                    left: 28px;
                }
            }
        }
    }
    .list {
        .summaryRow {
            background-color: #f4f5f8 !important;
        }
        div.addButton {
            height: 100%;
            padding: 0;
            margin-left: -52px;
            display: flex;
            align-items: center;
            button {
                font-size: 12px;
                font-weight: 600;
                cursor: pointer;
                display: flex;
                align-items: center;
                color: $dark-sky-blue;
                border: none;
                background: none;
                padding: 8px 12px;
                border-radius: 5px;
                background: none;
                opacity: 1;
                &:hover {
                    opacity: 1;
                    background-color: rgba(45, 159, 247, 0.06);
                }
            }
            svg {
                color: $dark-sky-blue;
                height: 20px;
                width: 20px;
                margin-right: 6px;
                margin-left: -4px;
            }
        }
        .cellTextContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            overflow: hidden;
            .editContainer {
                height: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                &.editMode {
                    border: 1px dashed #c3cbdb;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 0 4px;
                    border-radius: 3px;
                    > * {
                        pointer-events: none;
                    }
                }
                &.active {
                    border: 1px dashed $dark-sky-blue;
                    background-color: rgba(45, 159, 247, 0.06);
                }
            }
            .invoiceStatus {
                position: relative;
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 18px;
                }
                .crossLine {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: rotateZ(45deg);
                    height: 2px;
                    width: 100%;
                    background-color: #aaa;
                }
                svg {
                    height: 16px;
                    width: 16px;
                }
                &.invoiced {
                    svg {
                        color: $dark-sky-blue;
                    }
                }
                &.notInvoiced {
                    opacity: 0.4;
                    padding-right: 6px;
                    svg {
                        color: #c3cbdb;
                    }
                }
            }
            :global {
                .status-tag {
                    padding: 8px 12px;
                    min-width: 0;
                    font-size: 10px;
                }
            }
            p {
                margin: 0;
                white-space: nowrap;
                padding: 0;
                line-height: 1;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                text-align: left;
                &:nth-of-type(2) {
                    margin-top: 3px;
                    font-size: 10px;
                    &.green {
                        color: green;
                    }
                    &.red {
                        color: $faded-red;
                    }
                }
            }
            &.right {
                align-items: flex-end;
                p {
                    text-align: right;
                }
            }
        }
        .editable {
            cursor: pointer;
            &:hover {
                background-color: rgba(45, 159, 247, 0.06);
            }
        }
        .headerCell {
            font-weight: 600 !important;
            font-size: 14px !important;
            input {
                font-weight: 600 !important;
                font-size: 14px !important;
            }
        }
        .leftRowButtons {
            // margin-left: -60px;
            display: inline-block;
            // width: 60px;
            .drag {
                width: 20px;
                display: inline-block;
                cursor: grab;
                margin-left: 2px;
            }
            .more {
                width: 30px;
                display: inline-block;
            }
        }
        .descriptionCell {
            font-style: italic;
            input {
                font-style: italic;
            }
        }
        .rowContainer {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .hidden {
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }
        .popUp {
            position: absolute;
            background-color: white;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 200px;
            border-radius: 5px;
            padding: 16px;
            -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
            z-index: 999;
            > div {
                width: 100%;
                z-index: 999;
            }
        }
        :global {
            .header {
                background-color: white;
                padding: 0 24px;
                padding-left: 4px;
                border-bottom: 0;
                position: sticky;
                top: 205px;
                z-index: 998;
                page-break-inside: avoid !important;
                height: 50px;
                .column {
                    background-color: white;
                    height: 50px;
                    padding-top: 6px;
                    box-sizing: border-box;
                    .columnHeaderContent {
                        width: 100%;
                        overflow: hidden;
                    }
                    .titleSpan {
                        padding-left: 4px !important;
                        padding-right: 4px;
                    }
                    .rightSide {
                        display: none;
                    }
                }
            }
            .mainListWrapper {
                overflow: visible;
            }
            .mainList {
                overflow: visible;
                .row {
                    padding: 0 24px;
                    padding-left: 4px;
                    border-bottom: 1px solid #f4f5f8;
                    page-break-inside: avoid !important;
                    &.bottomMarker {
                        border-bottom: none;
                    }
                    .cell {
                        .context-menu {
                            min-width: unset;
                            padding: 0;
                        }
                        .textInput {
                            color: $dark;
                        }
                        input::placeholder {
                            color: #c3cbdb !important;
                            opacity: 1 !important;
                        }
                        &.editMode {
                            border: none;
                            .textInput {
                                border: 1px dashed #c3cbdb;
                                border-radius: 3px;
                                padding: 0 8px;
                            }
                        }
                    }
                    .inner {
                        font-size: 12px !important;
                        padding: 0 4px !important;
                        position: relative;
                        .cellValue {
                            padding: 0 !important;
                        }
                        .InfoSelect {
                            white-space: nowrap;
                            width: 100%;
                            overflow: hidden;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            > div {
                                width: 100%;
                                .is_textfield {
                                    > div {
                                        border: 1px dashed #c3cbdb;
                                    }
                                }
                            }
                        }
                        .autoCompleteInCell {
                            display: inline-flex;
                            align-items: center;
                            width: 100%;
                            padding-left: 0;
                            .autoCompleteInCell__control {
                                height: 80%;
                                border: 1px dashed #c3cbdb;
                                border-radius: 3px;
                                min-height: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.details {
    border-radius: 5px;
    box-sizing: border-box;
    width: 500px;
    background-color: white;
    height: 350px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .tabs {
        > div {
            width: 100%;
            > div {
                height: 100%;
                > div > div {
                    height: 100%;
                    button {
                        span {
                            line-height: 1.3 !important;
                        }
                    }
                }
            }
        }
    }
    .printSettings {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        > div {
            width: 100%;
        }
    }
    .quoteVersions {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        .addQuoteButton {
            border: none;
            background: $greenish-cyan;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                opacity: 0.6;
            }
            svg {
                color: white;
                width: 16px;
                height: 16px;
            }
        }
        > h2 {
            display: none;
        }
        > p {
            padding: 8px 22px 0 22px;
            font-size: 12px;
        }
        ul {
            width: 100%;
            list-style: none;
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 0;
            padding: 8px 0;
            overflow: auto;
            height: 100%;
            li {
                width: 100%;
                display: flex;
                align-items: stretch;
                border-bottom: 1px solid #f4f5f8;
                cursor: pointer;
                padding: 8px 22px;
                box-sizing: border-box;
                &:hover {
                    background-color: rgba(45, 159, 247, 0.06);
                }
                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                    cursor: not-allowed;
                    &:hover {
                        background-color: white;
                    }
                }
                &.active {
                    background-color: rgba(45, 159, 247, 0.2);
                    &:hover {
                        background-color: rgba(45, 159, 247, 0.2);
                    }
                }
                &:first-of-type {
                    border-bottom: none;
                    margin-bottom: 8px;
                    padding-right: 16px;
                    cursor: unset;
                    &:hover {
                        background-color: unset;
                    }
                }
                &:last-of-type {
                    border-bottom: none;
                }
                h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                }
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 11px;
                }
                > div {
                    position: relative;
                    > span {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -12px;
                    }
                    &:first-of-type {
                        flex: unset;
                        width: 70px;
                        flex-shrink: 0;
                    }
                    &:nth-of-type(2) {
                        flex: 1;
                    }
                }
                :global {
                    .context-menu {
                        min-width: unset;
                        margin-right: -16px;
                    }
                }
                .texts {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    justify-content: center;
                    button {
                        background: none;
                        border: none;
                        color: $dark-sky-blue;
                        min-width: 0;
                        padding: 0;
                        margin: 0;
                        font-size: 11px;
                        margin-top: 2px;
                        font-weight: 600;
                        text-align: left;
                        cursor: pointer;
                        width: fit-content;
                        &:hover {
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }
}

.viewOptions {
    min-width: 200px;
    padding: 16px 24px;
    .viewOptionRow {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        &:first-of-type {
            margin-bottom: 12px;
        }
        > div {
            width: 60px;
            &:last-of-type {
                width: unset;
                flex: 1;
            }
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-shrink: 0;
            overflow: hidden;
            .alwaysHidden {
                opacity: 0;
            }
            .active {
                color: $dark-sky-blue;
                svg {
                    color: $dark-sky-blue;
                }
            }
            span {
                padding: 0;
            }
            h2,
            p {
                margin: 0;
                padding: 0;
                font-size: 12px;
                white-space: nowrap;
            }
        }
    }
}

#tabQuotes {
    min-height: 200px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    .mainContent {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        align-items: center;
        .options {
            width: 100%;
            background-color: #f9f9f9;
            position: sticky;
            top: 70px;
            left: 0;
            right: 0;
            z-index: 9999;
            .optionsContainer {
                flex: 1;
                width: 100%;
                max-width: $max-paper-width;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 8px 0;
                box-sizing: border-box;
                > div {
                    display: flex;
                    align-items: center;
                    > button {
                        margin-right: 8px;
                        &.cancel {
                            background-color: #dde3e8;
                            color: #34495e;
                        }
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                    .optionsMenu {
                        margin-right: 8px;
                        > button {
                            border: none;
                            padding-right: 34px;
                            background-color: #dde3e8;
                            color: #34495e;
                            svg {
                                fill: #34495e;
                                background: #dde3e8;
                            }
                        }
                        :global {
                            #menu-list-grow {
                                svg {
                                    margin-right: 10px;
                                    margin-top: 2px;
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .content {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            padding: 0 32px 140px 50px;
            box-sizing: border-box;
            &.sticky {
                position: sticky;
                top: 151px;
                left: 0;
                right: 0;
                background-color: #f9f9f9;
                z-index: 999;
                margin-bottom: 8px;
                padding-bottom: 0;
            }
            > .left {
                max-width: $max-paper-width;
                flex: 1;
                .noQuoteView {
                    width: 100%;
                    height: 500px;
                    box-sizing: border-box;
                    background-color: white;
                    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }
            > .right {
                width: 300px;
                margin-left: 32px;
                position: relative;
                h2 {
                    margin: 0;
                    padding: 0;
                    font-size: 15px;
                    padding: 0 22px;
                }
                .targetingSectionToggle {
                    display: none;
                    position: absolute;
                    height: 80px;
                    background-color: white;
                    border: none;
                    width: 100%;
                    left: 0;
                    right: 0;
                    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    border-radius: 5px;
                    cursor: pointer;
                    svg {
                        color: $dark-sky-blue;
                    }
                    &:hover {
                        opacity: 0.6;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                        margin-top: 4px;
                        font-size: 11px;
                    }
                }
                .detailsToggle {
                    display: none;
                    height: 80px;
                    background-color: white;
                    border: none;
                    width: 100%;
                    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    border-radius: 5px;
                    cursor: pointer;
                    svg {
                        color: $dark-sky-blue;
                    }
                    &:hover {
                        opacity: 0.6;
                    }
                    p {
                        margin: 0;
                        padding: 0;
                        margin-top: 4px;
                        font-size: 11px;
                    }
                }
                .details {
                    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    width: 100%;
                    > div {
                        > h2 {
                            display: unset;
                        }
                    }
                }
                .targetingSection {
                    position: absolute;
                    left: 0;
                    right: 0;
                    background-color: white;
                    width: 100%;
                    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
                    border-radius: 5px;
                    z-index: 998 !important;
                    .totalRow {
                        padding: 8px 24px;
                        &:nth-of-type(2) {
                            margin-top: 31px;
                        }
                        .totalValues {
                            margin-left: 0;
                            max-width: unset;
                            width: 100%;
                            > div {
                                flex: unset;
                                margin-left: 0;
                                align-items: flex-end;
                                padding: 0 4px;
                                flex-shrink: 0;
                                box-sizing: border-box;
                                text-align: right;
                                &:first-of-type {
                                    width: 118px;
                                }
                                &:last-of-type {
                                    width: 134px;
                                }
                                h3 {
                                    font-size: 12px;
                                    margin-bottom: 2px;
                                }
                                p {
                                    &:nth-of-type(2) {
                                        font-size: 10px;
                                    }
                                    &.green {
                                        color: green;
                                    }
                                    &.red {
                                        color: $faded-red;
                                    }
                                }
                            }
                        }
                    }
                    .titleContainer {
                        display: flex;
                        align-items: center;
                        background-color: #f9f9f9;
                        margin-bottom: 6px;
                        height: 48px;
                        h2 {
                            padding: 0 16px;
                            margin: 0;
                            font-size: 14px;
                        }
                        //wow
                        > div {
                            width: 100%;
                            > div {
                                height: 100%;
                                > div > div {
                                    height: 100%;
                                    button {
                                        span {
                                            line-height: 1.3 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.noDisplay {
    display: none;
    width: 0;
    height: 0;
}

@media screen and (max-width: 1800px) {
    .listContainer {
        .list {
            .rowIndicators.multiple {
                left: -45px;
                > div {
                    &:nth-of-type(2) {
                        position: absolute;
                        left: 15px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    #tabQuotes {
        .mainContent {
            .content {
                padding: 0 24px 140px 50px;
                &.sticky {
                    padding-bottom: 0;
                }
                > .right {
                    width: 80px;
                    flex-shrink: 0;
                    margin-left: 24px;
                    .details {
                        position: absolute;
                        z-index: 99;
                        top: 0;
                        left: 120px;
                        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
                        width: 300px;
                        opacity: 0;
                        &.open {
                            transform: translateX(-445px);
                            opacity: 1;
                        }
                    }
                    .targetingSection {
                        left: 120px;
                        z-index: 99;
                        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
                        width: 300px;
                        opacity: 0;
                        &.open {
                            transform: translateX(-445px);
                            opacity: 1;
                        }
                    }
                    .targetingSectionToggle {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .detailsToggle {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #tabQuotes {
        .mainContent {
            .content {
                padding: 0 16px 140px 50px;
                &.sticky {
                    padding-bottom: 0;
                }
                > .right {
                    width: 40px !important;
                    margin-left: 16px !important;
                    .details {
                        &.open {
                            transform: translateX(-437px);
                        }
                    }
                    .targetingSection {
                        &.open {
                            transform: translateX(-437px);
                        }
                    }
                    .targetingSectionToggle,
                    .detailsToggle {
                        height: 40px;
                        p {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .quote {
        .topSection {
            .logoDates {
                display: none;
            }
            .addresses {
                > .dates {
                    width: 110px !important;
                }
                :global {
                    .label-field {
                        flex-direction: column;
                        height: unset !important;
                        align-items: stretch;
                        &.quote-header-email-field {
                            .cellValue {
                                padding: 0;
                                margin-top: 3px;
                            }
                        }
                        .disabled {
                            > div {
                                padding-left: 0;
                            }
                        }
                        .label {
                            flex: 0;
                            font-weight: 600;
                        }
                    }
                }
                &.readMode {
                    :global {
                        .label-field {
                            .paperLabelField {
                                > div {
                                    padding-left: 0;
                                    border: none !important;
                                }
                                .datePickerInput {
                                    padding-left: 0;
                                    border: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
