@import '../colors.scss';

.tabInfoButton {
    display: flex;
    align-items: center;
    margin-left: 8px;
    p {
        margin: 0;
        padding: 0;
        color: $bluey-purple;
        font-weight: 500;
        font-size: 12px;
        margin-right: 4px;
    }
    .contextMenu {
        button {
            padding-right: 28px !important;
        }
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        background: none;
        cursor: pointer;
        padding: 6px 12px 6px 12px;
        margin: 0;
        margin-left: 2px !important;
        min-width: 0;
        font-size: 12px;
        text-transform: none;
        color: $bluey-purple;
        svg {
            color: $bluey-purple;
            fill: $bluey-purple;
            background: transparent;
        }
        &:hover {
            background-color: $white-two;
        }
        img {
            height: 18px;
            width: 18px;
            color: $bluey-purple;
            margin-right: 8px;
        }
    }
    :global {
        #menu-list-grow {
            ul {
                li {
                    display: flex;
                    align-items: center;
                    img {
                        height: 18px;
                        width: 18px;
                        color: $bluey-purple;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
