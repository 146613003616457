@import '../colors.scss';
#invoiceLogView {
    flex: 1;
    width: 500px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    > img {
        height: 120px;
        width: 120px;
        margin-top: 24px;
    }
    h2, p {
        margin: 0;
        padding: 0;
    }
    .noDataView {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        h1 {
            margin: 0;
            padding: 0;
            font-size: 18px;
            margin-top: 24px;
            margin-bottom: 4px;
        }
    }
    ul {
        list-style: none;
        margin-block-start: 0;
        padding-inline-start: 0;
        width: 100%;
        box-sizing: border-box;
        .invoiceLogViewRow {
            min-height: 50px;
            margin: 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
            padding: 16px;
            width: 100%;
            box-sizing: border-box;
            .iconContainer {
                height: 40px;
                width: 40px;
                background-color: $dark-sky-blue;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                flex-shrink: 0;
                svg {
                    color: white;
                    width: 20px;
                    height: 20px;
                }
            }
            p {
                font-size: 12px;
                color: $subtitle;
            }
            .mainText {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-left: 16px;
                margin-right: 16px;
                p:first-of-type {
                    font-size: 10px;
                }
                h2 {
                    font-size: 13px;
                }
                .message {
                    font-style: italic;
                    &.breakAll {
                        word-break: break-all;
                    }
                }
            }
            .right {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-end;
                justify-content: center;
                flex-shrink: 0;
                :global {
                    .status-tag {
                        padding: 6px;
                    }
                }
            }
        }
    }
}
