.row {
    .hours_done {
        display: flex;
        justify-content: end;
        padding-right: 8px;

        span {
            &:first-child {
                margin-right: 8px;
            }
        }
    }

    .hours {
        input {
            padding: 0 6px;
            width: 37px;
            text-align: center;
        }
    }

    button {
        padding: 4px;

        &.disabled {
            opacity: 0.6;
        }
    }
}