@import '../colors.scss';

.statistics {
    padding-top: 0;
    background-color: transparent;
    :global {
        .content-block {
            &:first-of-type {
                margin-top: 0 !important;
            }
        }
    }
}

.refreshAsiakastietoButton {
    background: none;
    min-width: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    &:hover {
        background-color: $white-two;
    }
}
