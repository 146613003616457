.insight-drop-down {
  button {
    background-color: #c9e8ff;
    color: #2d9ff7;
    white-space: nowrap;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .icon {
    margin-right: -8px;
    margin-left: 5px;
    color: #2d9ff7;
  }
}

.insight-drop-down-tab-row {
  min-width: 70px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
  &:first-child {
    padding-top: 15px;
  }
  &:last-child {
    padding-bottom: 15px;
  }
  p {
    margin: 0;
    padding: 0;
    flex: 1;
  }
  .icon {
    opacity: 0.4;
    height: 20px;
    width: 20px;
    margin-right: 15px;
  }
  &:hover {
    background-color: rgba(44, 159, 247, 0.08);
    cursor: pointer;
  }
}
