.dialog {
    width: 80%;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .headerContainer {
        padding: 20px 25px;
        width: 100%;
        display: flex;
        align-items: center;

        h1 {
            margin: 0;
            padding: 0;
            font-size: 20px;
            color: #2c3547;
        }

        p {
            color: #808285;
            font-weight: bold;
        }
    }

    .dialogButtons {
        display: flex;
        background: #eee;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        padding: 20px 30px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 12px;
            border-radius: 5px;
            padding: 15px 40px;
			background-color: #2d9ff7;
			color: white;
			border: 0;

            &.cancel {
                background: transparent;
                color: black;
            }

            &:hover {
                opacity: 0.5;
                cursor: pointer;
            }

			&:disabled {
				opacity: 0.4;

				&:hover {
					opacity: 0.4;
					cursor: not-allowed;
				}
			}
        }
    }

    .content {
        flex: 1;
		padding: 1em;
    }
}
