.menuItem {
    position: relative;
    .main {
        cursor: pointer;
        :global {
            .name {
                flex: 1;
                min-width: 0;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            box-sizing: border-box;

            span.count {
                margin-left: 5px;
                margin-right: 2px;
                font-size: 12px;

                &.limit-reached {
                    color: #f97fab;
                }
            }

            div.lock {
                display: flex;
                align-items: center;
            }

            div.lock {
                svg {
                    fill: white;
                    height: 18px;
                    width: 18px;
                }
            }
            div.trial {
                svg {
                    fill: white;
                    height: 25px;
                    width: 25px;
                    g {
                        fill: #b2b9c9;
                    }
                }
            }
        }
    }
    &:hover,
    &:active {
        > .main {
            :global(.menu-item:not(.main-item)) {
                background-color: #2c3547;
                color: white !important;
                &:first-of-type {
                    border-top-right-radius: 5px;
                }
                &:last-of-type {
                    border-bottom-right-radius: 5px;
                }
            }
        }
        .hoverMenu {
            display: unset;
        }
    }
    .hoverMenu {
        position: fixed;
        display: none;
        background-color: #3a455b;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 999999;
        &.tabletMode {
            border-top-right-radius: 0;
            .hoverMenuTitle {
                display: flex;
            }
            :global {
                .menu-item {
                    border-top-right-radius: 0 !important;
                }
            }
        }
        .hoverMenuTitle {
            position: absolute;
            top: -30px;
            width: 100%;
            background-color: #2c3547;
            border-top-right-radius: 5px;
            height: 30px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            box-sizing: border-box;
            display: none;
            h3 {
                padding: 0;
                margin: 0;
                color: white;
                font-size: 12px;
            }
        }
        .main {
            :global {
                .menu-item {
                    .name {
                        margin-left: 20px;
                        min-width: 200px;
                        padding-right: 50px;
                    }
                }
            }
        }
    }
}
