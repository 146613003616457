@import './shared.scss';

.tooltip {
    max-width: 450px !important;

    div {
        &:first-child {
            padding-bottom: 10px;
        }
    }
}

.dialog {

    .full {
        width: 100%;
        margin: 0;
    }

    :global(.rdrDateRangePickerWrapper) {
        padding-bottom: 10px;
    }
    :global(.dateWrapper.date) {
        padding-bottom: 10px;
    }

    .dialogTitle {
        text-align: center;
        border-top: 5px solid #2D9FF7;
    }

    .fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-left: 24px;
        padding-right: 24px;

        > div.row {
            display: contents;

            > div {
                padding-bottom: 15px;

                &:first-child {
                    margin-right: 5px;
                }

                &:last-child {
                    margin-left: 5px;
                }
            }
        }
    }

    .userGrid {
        display: grid;
        grid-template-columns: repeat(9, max-content);

        > div.row {
            display: contents;

            &.taskNames {
                > div {
                    color: #333333;
                    font-size: 13px;
                    margin-bottom: 5px;
                    display: flex;
                    justify-content: center;
                    font-weight: bold;

                    &:first-child {
                        grid-column: 4 / span 2;
                    }

                    grid-column: span 2;
                }
            }

            &.header {
                font-weight: bold;
                font-size: 10px;
                line-height: 14px;
                
                > div {
                    background: #F4F5F8;
                    padding: 10px;
                    margin-bottom: 10px;

                    &:first-child {
                        grid-column: 1;
                    }
                }
            }

            &.footer {
                font-weight: bold;

                > div {
                    margin-top: 5px;

                    &.resourced  {
                        padding-left: 16px;
                    }
                }
            }

            > div {
                display: flex;
                align-items: center;
                padding: 0 10px;
                font-size: 11px;

                &:first-child {
                    padding-left: 24px;
                }

                &:last-child {
                    padding-right: 24px;
                }
            }
        }
    }

    .paper {
        max-width: 90%;
    }

    .content {
        min-width: 600px;
        min-height: 480px;
        padding-left: 0;
        padding-right: 0;
    }

    .actions {
        justify-content: space-between;
        
        .cancel, .ok {
            text-transform: uppercase;
        }
    }
}