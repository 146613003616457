@mixin notSelectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin threeDotOverflownText {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin generalTextOnList {
	font-family: "Open Sans" !important;
	font-size: 12px !important;
	color: #575962 !important;
}