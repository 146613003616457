$total-left-row-color: #f9f9f9;
$total-right-row-color: #d7d7df;
$gantt-selected-color: #2d9ff720;

#gantt-area {

    .gantt_tree_icon.gantt_close, .gantt_tree_icon.gantt_open {
        background-image: none !important;
        color: gray;
        display: inline-flex;
        align-items: center;

        img {
            width: 25px;
            height: 25px;
        }
    }

    .gantt-svg-icon {
        display: inline-flex;
        align-items: center;
        vertical-align: top;
        height: 100%;
        width: 18px !important;

        svg {
            width: 15px;

            &.milestone-icon {
                width: 13px;
            }
        }

        svg.arrow_up {
            transform: rotate(180deg);
        }
    }

    .gantt_tree_indent {
        width: 18px !important;
    }

    .gantt_layout_cell.gantt_ver_scroll {
        z-index: 0 !important;
    }

    .gantt_user_availability {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 39px;

        &.gantt_user_availability_negative {
            color: red;
        }
    }

    .gantt_user_availability_row {
        background: #ebebeb66 !important;
    }

    .gantt-toolbox {
        position: absolute;
        width: 200px;
        height: 20px;
        z-index: 200;
        display: none;
    }

    .gantt_outside_range {
        background: #f4f7f4 !important;
    }

    .GanttMenuContainer {
        position: absolute;
        z-index: 200;
        display: none;

        > div {
            border-radius: 10px;
            background: #00000020;
            color: #000000;

            button {
                padding: 3px;
                min-width: 0;
            }
        }

        #menu-list-grow li.gantt-menu-danger {
            color: #d62238 !important;
        }
    }

    .gantt_split_indicator {
        position: absolute;
        bottom: 0;
        height: 30px;
        width: 1px;
        background-color: #00000055;
        cursor: pointer;
    }

    .priorityIcon {
		margin-right: 8px;
	}

	.gantt-container {
		.gantt_marker {
			&.today {
				background-color: #6f42c1;
			}
		}
		.gantt_grid_head_cell {
			text-align: left;
			padding-left: 6px;
			font-size: 12px;
			color: #34495e;
		}
		.gantt_data_area {
			position: relative;
			.gantt_task_bg {
				pointer-events: none;
			}
			&:not(.drawing) {
				.draw-bar {
					display: none;
				}
			}
			.draw-bar {
				height: 29px;
				background-color: #81c6fb;
				position: absolute;
				border-radius: 2px;
				border: 1px solid #2898b0;
				opacity: 0.5;				
				.date {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					font-size: 11px;
					&:first-child {
						padding-right: 4px;
						right: 100%;
					}
					&:last-child {
						padding-left: 4px;
						left: 100%;
					}
				}
			}
			&.drawing * {
				pointer-events: none;
			}
		}
		.gantt_row {
			cursor: pointer;

			&.is-project, &.is-taimer-project {
				// background-color: #f9f9f9;
				a {
					color: #2d9ff7;
				}
			}
			&.is-global_total {
				font-weight: 600;
			}
			&:not(.can-add-task) {
				.gantt_add {
					visibility: hidden;
				}
			}

			&.gantt-root {
				background: #F7F8FC;
				border-bottom: 1px solid #DDE3E8;

				&.gantt-open {
					border-bottom: 2px solid #DDE3E8;
				}
			}

			&.gantt-group {
				background: #f3f4fb;
				border-bottom: 1px solid #DDE3E8;

				&.gantt-open {
					border-bottom: 2px solid #DDE3E8;
				}
			}

			&.gantt_selected {
				background-color: $gantt-selected-color;

				&:hover {
					background-color: $gantt-selected-color;
				}
			}
			&:hover {
				background-color: #f9f9f9;
			}
			// &.no-parent {
			// 	.gantt_tree_indent {
			// 		display: none;
			// 	}
			// }
			&.is-total {
				// background-color: $total-left-row-color;
			}
		}
		// .gantt_row.gantt_selected .gantt_cell {
		// 	background-color: #f9f9f9;
		// }
		.gantt_row.is-project, .gantt_row_task.is-taimer-project, .gantt_row_task.is-total {
			.gantt_tree_icon + .gantt_tree_content {
				font-weight: bold;
			}
		}
		.gantt_cell {
			.gantt_tree_content {
				font-size: 11px;
			}
			.customer, .gantt_text_row, .project {
				display: block;
				height: 16px;
				line-height: 16px;
			}
			.customer {
				margin-top: 4px;
			}
			.project {
				margin-top: 4px;
				color: #2EABE2;
			}
			.gantt_text_row {
				margin-top: 0;
			}
			.percentage {
				font-size: 90%;
				color: rgba(0, 0, 0, 0.7);
				margin-left: 2px;
			}

		}
		.gantt_task_line {
			.gantt_task_content {
				font-size: 10px;
				font-weight: bold;
			}
			&.gantt_project {
				background-color: #f5a623;
				border-color: #f5a623;
			}
			&.is-taimer-project {
				background-color: #a6aec1;
				border-color: #6b7897;

				&.vacation-project {
					background-color: #42a942;
					border-color: #42a942;
					.gantt_task_progress {
						background-color: green;
					}
				}

				.gantt_task_progress {
					background-color: #6b7897;
				}
				.gantt_task_content {
					padding-left: 8px;
					padding-right: 8px;
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;
					.start, .end {
						padding-top: 4px;
						.date, .month {
							height: 9px;
							line-height: 9px;
							font-size: 9px;
							font-weight: normal;
						}
					}
				}

			}

			&.gantt_split_parent {
				display: none;
			}

			&.is-topic {
				background-color: #a6c1b9;
				border-color: #6b978a;
				.gantt_task_progress {
					background-color: #6b978a;
				}
			}

			&.is-task, &.is-task-part {
				background-color: #81c6fb;
				border-color: #2D9FF7;
				.gantt_task_progress {
					background-color: #2d9ff7;
				}

				&:hover, &.gantt_selected {
					.gantt_side_content {
						&.gantt_left {
							padding-right: 24px;
						}
						&.gantt_right {
							padding-left: 24px;	
						}
					}
				}

				.gantt_side_content {
					&.gantt_left {
						pointer-events: none;
						padding-right: 4px;
					}
					&.gantt_right {
						pointer-events: none;
						padding-left: 4px;	
					}
				}

				&.done {
					background-color: #a6c1b9;
					border-color: #6b978a;
					.gantt_task_progress {
						background-color: #6b978a;
					}
				}

			}

			&.is-milestone {
				height: 20px !important;
				width: 20px !important;
				margin-top: 4px;
				margin-left: 5px;
				.gantt_side_content {
					&.gantt_left {
						pointer-events: none;
						padding-right: 7px;
						margin-top: -5px;
					}
					&.gantt_right {
						pointer-events: none;
						margin-top: -5px;
					}
				}
			}

			&.is-total {
				background-color: $total-right-row-color;
				border-color: $total-right-row-color;
				height: 10px !important;
				margin-top: 10px;
				border-radius: 6px;
				font-weight: bold;
				.gantt_task_content, .gantt_link_control, .gantt_task_progress_drag {
					display: none;
				}
				&.gantt_selected {
					box-shadow: none;
				}
				// &:before, &:after {
				//     content: "";
				//     width: 0;
				//     height: 0;
				//     border-style: solid;
				//     border-width: 10px 10px 0px 0px;
				//     border-color: $total-right-row-color transparent transparent transparent;
				//     position: absolute;
				//     top: 100%;
				// }
				// &:before {
				// 	left: -1px;
				// }
				// &:after {
				// 	right: -1px;
				// 	transform: scaleX(-1);
				// }
			}
		}
		.gantt_task_row.gantt_selected {
			background-color: $gantt-selected-color;
		}
	}

	.owner-label{
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 12px;
		display: inline-block;
		border: 1px solid #cccccc;
		border-radius: 25px;
		background: #e6e6e6;
		color: #6f6f6f;
		margin: 0 3px;
		font-weight: bold;
		text-align: center;
	}
}