.withTabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 1;
    .withTabs {
        z-index: 0;
    }
    .titleSection {
        height: 50px !important;
        padding-left: 28px !important;
        padding-right: 28px !important;
        margin-top: 2px !important;
        z-index: 1;
        h1 {
            line-height: unset !important;
        }
    }
    .noTabs {
        margin-bottom: -8px !important;
    }
}

@media screen and (max-height: 700px) {
    .withHeader {
        margin-top: 12px;
        .titleSection {
            display: none !important;
        }
    }
}
