#goals-settings {
    .setting-block {
        margin-bottom: 40px;
        p {
            margin: 0;
            margin-top: 8px;
            margin-bottom: 20px;
        }
        .goal-data-list {
            width: 325px;
            max-width: 90%;
        }
        .commitment-container {
            display: flex;
            align-items: center;
            p {
                margin: 0;
                margin-right: 40px;
                font-size: 14px;
            }
        }
        .selected-tags {
            > * {
              margin-right: 10px;
              margin-top: 10px;
            }
          }
    }
}