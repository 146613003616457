@import '../colors.scss';

.container {
    flex: 1;
    overflow: hidden;
    display: flex;
    :global {
        img.priorityIcon {
            margin-right: 8px;
        }
    }
}

.selectedProjects {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > span {
        background-color: $dark-sky-blue;
        border-radius: 4px;
        color: white;
        font-size: 12px;
        padding: 5px 10px;
        margin-right: 6px;
        margin-bottom: 4px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: inline-block;
        .remove {
            cursor: pointer;
            font-weight: bold;
            margin-left: 16px;
        }
    }
}

.dateRange {
    display: flex;
    align-items: center;
    :global {
        .is-one-day .inputWrapper {
            .date-separator,
            .second {
                display: none;
            }
        }
    }
    .bookHours {
        display: flex;
        font-size: 12px;
        margin-left: 16px;
        div {
            margin-left: 4px;
            color: #27abe2;
            cursor: pointer;
        }
    }
}

.resourceButtons {
    display: grid;
    grid-column: 1/-1;

    grid-template-columns: max-content 1fr max-content;

    .updateHoursButton {
        grid-column: 1;
    }

    .repeatButton {
        grid-column: 3;
    }
}

.buttonRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.usersContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
}

.optionsMenu {
    button {
        border: none;
        color: $dark-sky-blue;
        padding-right: 25px;
        padding-left: 8px;
        &:hover {
            border: none;
        }
        svg {
            fill: $dark-sky-blue;
            background-color: transparent;
        }
    }
    :global {
        #menu-list-grow {
            li {
                font-size: 12px;
                padding-top: 4px;
                padding-bottom: 4px;
                font-weight: bold;
                svg {
                    color: $dark !important;
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    margin-left: -2px;
                }
            }
        }
    }
}

.divider {
    height: 1px;
    width: 100%;
    margin: 20px 0;
    background-color: $steel;
    opacity: 0.4;
}

.users {
    display: grid;
    grid-template-columns: 1fr max-content max-content min-content;
    font-size: 12px;
    align-items: center;

    > div {
        display: contents;

        .hoursDone {
            display: flex;
            justify-content: end;
            padding-right: 8px;

            span {
                &:first-child {
                    margin-right: 8px;
                }
            }
        }

        .twocol {
            grid-column: span 2;
        }

        &.header,
        &.footer {
            font-weight: bold;

            .hours {
                text-align: center;
                padding-right: 10px;
            }
        }

        &.user {
            .nametitle {
                display: flex;
            }
            .title {
                color: #999;
            }

            .hours {
                input {
                    padding: 0 6px;
                    width: 37px;
                    text-align: center;
                }
            }
            button {
                padding: 4px;
                &.disabled {
                    opacity: 0.6;
                }
            }
        }
    }
}

.repeatButton,
.updateHoursButton {
    &.disabled {
        visibility: hidden;
    }
    svg {
        margin-right: 4px;
        width: 18px;
        height: 18px;
        color: #27abe2;
        fill: currentColor;
    }
    display: flex;
    justify-content: flex-end;
    align-content: center;
    cursor: pointer;
    color: #27abe2;
    font-size: 12px;
    grid-column: 2;
    text-align: right;
}
