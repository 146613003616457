.closeableSectionView {
    width: 100%;
    display: flex;
    flex-direction: column;
    .section {
        border-bottom: 1px solid #eee;
        padding: 16px 0;
        .header {
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            h2 {
                padding: 0 !important;
                margin: 0 !important;
            }
            &:hover {
                opacity: 0.6;
            }
        }
    }
    .content {
        > h3 {
            padding: 0;
            margin: 0;
            font-size: 13px;
        }
    }
}
