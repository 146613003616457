@import '../colors.scss';

.container {
    background: $white_two;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 20px;
}

.leftColumn {
    flex: 4 0 0px;
    min-width: 750px;
    max-width: 1800px;
}

.rightColumn {
    margin-top: 102px;
    flex: 1 0 0px;
    margin-left: 0px;
    min-width: 300px;
    max-width: 400px;
}

.buttonContainer {
    padding: 23px 3% 23px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menuContainer {
    border: 1px solid #f0f0f0;
    background: #fff;
    height: calc(100% - 37px);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.menucontentcontainer {
    padding: 10px;
}

.invoiceId {
    font-size: 14px;
    color: $subtitle;
    font-weight: 600;
}

.datalistInputRoot {
    width: unset;
}

.chips {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
}

.chip {
    margin: 5px;
    border-radius: 4px;
    height: auto;
    min-height: 30px;
}

.chipLabel {
    max-width: 270px;
    white-space: normal;
}

.msChip {
    margin: 5px;
    width: 97%;
    justify-content: start;
}

.msAvatar {
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark_sky_blue;
}

.msSvg {
    transform: scale(0.8);
    color: white;
}

.msLabel {
    color: $dark_grey_blue;
}

.alignRight {
    text-align: right;
    padding-right: 6px !important;
}

.footer {
    display: flex;
    width: 100%;
    height: 13%;
    background: $white_two;
    bottom: 0;
    box-sizing: border-box;
    padding: 32px;
    padding-top: 16px;
}

.headerText {
    grid-area: header;
    place-self: end start;
    font-weight: 600;
    font-size: 28px;
    color: #515151;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.headerNumber {
    font-size: 18px;
    opacity: 0.8;
    align-self: flex-end;
    margin-bottom: 3px;
}

.billingRequisites {
    grid-area: requisites;
    display: flex;
    justify-content: space-between;
    min-height: 360px;
}

.paperContent {
    box-sizing: border-box;
    padding: 0 32px 32px 32px;
    display: flex;
    min-height: 490px;
}

.totals {
    flex: 3 1 0px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.pointer {
    cursor: pointer;
}

.materialButtonContainer {
    height: 46px;
    width: 100%;
    background-color: $pale_grey_three;
    padding: 0 32px 0 32px;
    box-sizing: border-box;
    background-clip: content-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.materialButton {
    color: $dark_sky_blue;
    cursor: pointer;
    font-weight: 700;
    padding: 0;
    margin: 0 16px 0 16px;
    display: inline-block;
    height: 100%;
    line-height: 46px;
}

.materialIcon {
    margin-right: 8px;
    color: #2d9ff7;
}

.divider {
    height: 1px;
    box-sizing: border-box;
    margin-left: 2%;
    width: 96%;
    position: absolute;
    bottom: 200px;
    background-color: #515151;
    z-index: 1;
}

.referencenumber {
    background: $white_two;
    text-align: right;
    padding: 0 32px 10px 0;
    color: #9da8bb;
    font-weight: 600;
    text-transform: uppercase;
}

.hidden {
    display: none;
}

.alreadyInvoicedRow {
    margin-left: 70px;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 600;
}
