/* zeplin https://app.zeplin.io/styleguide/5d0b5f333ffa80708b4b8afa/colors */

$dark: #2c3547;
$dark-grey-blue: #34495e;
$steel: #6b7897;
$bluey-purple: #6f42c1;
$cloudy-blue: #b2b9c9;
$light-sky-blue: #dde3e8;
$pale-lavender: #ece9f1;
$white: #ffffff;
$dark-sky-blue: #2d9ff7;
// $greenish-cyan: #50e3c2;
$greenish-cyan: #20C6A1;
$warm-pink: #f7548f;
$orangey-yellow: #f5a623;
$maize: #f7bc59;
$egg-shell: #fce8c7;
$melon: #ff8058;
$peachy-pink: #ffa082;
$light-peach: #ffd4c7;
$deep-sky-blue: #0084f4;
$sky-blue: #62b7f9;
$light-sky-blue-two: #cbe7fd;
$carnation-pink: #f97fab;
$light-pink: #fed5e4;
$tiffany-blue: #7bead1;
$light-light-blue: #d4f8f0;
$black-50: rgba(0,0,0,0.5);
$pale-grey: #f7f5f9;
$scarlet: #d0021b;
$faded-red: #dc4054;
$pastel-pink: #f4bfc6;
$white-two: #f9f9f9;
$black-54: rgba(0,0,0,0.54);
$eggplant: #1a051d;
$cornflower: #6979f8;
$pale-grey-two: #f8f9fa;
$pale-grey-three: #f4f5f8;

$subtitle: $dark-grey-blue;
$title: $dark;
$list-header-bg: #dee7f0;
$border: #c3cbdb;

:export {
    subtitle: $subtitle;
    title: $title;
    list_header_bg: $list-header-bg;
    border: $border;
}