@import '../colors.scss';

.container {
    display: flex;
}
.fieldEditSlider {
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .top {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        background-color: $dark-grey-blue;
        color: white;
        padding: 0 16px;
        box-sizing: border-box;
        h1 {
            margin: 0;
            padding: 0;
            font-size: 16px;
        }
    }
    .fields {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 16px;
        padding-top: 6px;
        box-sizing: border-box;
        overflow: auto;
        .field,
        .additionalFields {
            width: 100%;
            .errorMessage {
                margin-top: 0;
                p {
                    color: $faded-red;
                    margin: 0;
                    margin-top: 4px;
                    padding: 0;
                    font-size: 11px;
                    font-weight: bold;
                }
            }
            > h3 {
                margin: 20px 0 10px 0;
                font-size: 13px;
            }
            .row {
                display: flex;
                align-items: center;
                .datalistContainer {
                    display: flex;
                    flex-direction: column;
                    max-width: 100%;
                }
                &.headerRow {
                    margin: 20px 0 10px 0;
                }
                > h3 {
                    margin: 0;
                    font-size: 13px;
                }
                > div {
                    margin: 0;
                    &:first-of-type {
                        flex: 1;
                    }
                    &:nth-of-type(2) {
                        margin-left: 8px;
                    }
                }
                .tooltip {
                    margin-left: 12px !important;
                    svg {
                        width: 20px;
                        height: 20px;
                        color: #aaa;
                    }
                }
            }
            > div:not(.field) {
                width: 100%;
                margin: 0;
                margin-top: 10px;
            }
            .adornment {
                height: 30px;
                width: 30px;
                svg {
                    color: $dark-sky-blue;
                    height: 20px;
                    width: 20px;
                }
            }
            :global {
                .daterange,
                .date {
                    > div:not(.calendarView) {
                        height: 46px;
                    }
                }
                .textField-with-limit {
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .treeDropdown {
                    > div {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
                .react-select-root {
                    > div {
                        > .textfield {
                            margin-top: 0;
                            margin-bottom: 0;
                            > div {
                                box-sizing: border-box;
                                .datalistValue {
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }
                .customFieldTextarea {
                    margin-bottom: 12px;
                }
            }
            .radioRow {
                display: flex;
                align-items: center;
                > span {
                    padding: 0;
                    margin-right: 12px;
                }
            }
            .switchRow {
                display: flex;
                align-items: center;
                .dateSwitchValue {
                    display: flex;
                    align-items: center;
                    p {
                        margin: 0;
                        font-size: 12px;
                    }
                    :global {
                        .yet-another-date-design {
                            height: unset;
                            .datePickerInput {
                                border: 0;
                                width: 64px;
                                font-size: 12px;
                                color: #2d9ff7;
                                margin-left: 4px;
                            }
                            .formControl {
                                margin: 0;
                            }
                            svg {
                                margin-top: -3px;
                                font-size: 15px;
                            }
                            .calendarView {
                                right: -110px;
                                top: 30px;
                                z-index: 500;
                            }
                            &.display-line {
                                .inputWrapper::before {
                                    content: '-';
                                    position: absolute;
                                    margin-left: 32px;
                                    color: #2d9ff7;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
                .switch {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    > span {
                        margin-right: 4px;
                        margin-left: -7px;
                    }
                    label {
                        span {
                            font-size: 12px;
                        }
                    }
                    p {
                        padding: 0;
                        margin: 0;
                    }
                }
                :global {
                    .dateWrapper.date {
                        width: 200px;
                    }
                }
            }
            .nestedRow {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
            }
        }
    }
    :global {
        .subsection {
            label {
                font-size: 14px;
                font-weight: bold;
                color: #6b7897;
                white-space: nowrap;
            }
            justify-content: stretch;

            div.gray_border {
                width: 100%;
                border-bottom: solid 1px #dde3e8;
                margin-left: 15.5px;
            }
        }
        .TMRInvoiceRows {
            margin-top: 16px !important;
            p {
                padding-top: 2px;
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 16px 16px;
    box-sizing: border-box;
    .right {
        display: flex;
        align-items: center;
        .options {
            button {
                border: none;
                background-color: $light-sky-blue;
                color: $dark;
                padding-right: 28px;
                padding-left: 16px;
                &:hover {
                    border: none;
                }
                svg {
                    fill: $dark;
                    background-color: transparent;
                }
            }
            &.add {
                button {
                    background-color: $greenish-cyan;
                    color: white;
                    svg {
                        fill: white;
                    }
                }
            }
            :global {
                #menu-list-grow {
                    li {
                        font-size: 12px;
                        padding-top: 4px;
                        padding-bottom: 4px;
                        font-weight: bold;
                        svg {
                            color: $dark !important;
                            width: 20px;
                            height: 20px;
                            margin-right: 4px;
                            margin-left: -2px;
                        }
                    }
                }
            }
        }
    }
    button {
        margin-left: 8px;
    }
}
