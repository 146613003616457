@import '../colors.scss';

.dateRangePopper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .removeDueDateButton {
        background-color: $carnation-pink !important;
        color: white !important;
        margin-bottom: 12px;
        margin-top: -8px;
    }
}

#menu {
    li {
        font-size: 13px !important;
        svg {
            margin-right: 8px;
            height: 20px;
            width: 20px;
            color: $dark;
        }
        &.delete {
            color: $carnation-pink !important;
            svg {
                color: $carnation-pink !important;
                height: 25px;
                width: 20px;
            }
        }
    }
}

#personalNote {
    .slider {
        width: 600px;
        max-width: 95vw;
        display: flex;
        flex-direction: column;
        span[placeholder]:empty:before {
            content: attr(placeholder);
            color: $steel;
            pointer-events: none;
            display: block;
        }
        span[contenteditable='true'] {
            outline: none;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
        .inputContainer {
            width: 100%;
            box-sizing: border-box;
            padding: 16px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $border;
            flex-shrink: 0;
            .input {
                box-sizing: border-box;
                border: none;
                background-color: $list-header-bg;
                border-radius: 4px;
                min-height: 33px;
                padding: 8px 12px;
                white-space: pre-wrap;
                display: inline-block;
                overflow: hidden;
                font-size: 15px;
            }
            .actions {
                margin-top: 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .selections {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    > button {
                        display: flex;
                        align-items: center;
                        border: none;
                        background: none;
                        padding: 0;
                        cursor: pointer;
                        font-family: 'Open Sans', sans-serif;
                        color: $dark;
                        font-size: 12px;
                        margin-right: 4px;
                        p {
                            line-height: 1;
                            margin: 0;
                            padding: 0;
                            margin-top: 3px;
                        }
                        svg {
                            width: 20px;
                            height: 20px;
                            margin-right: 6px;
                            color: $dark;
                        }
                    }
                }
                > button {
                    background-color: $greenish-cyan;
                    color: white;
                    text-transform: uppercase;
                }
            }
        }
        .filters {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-shrink: 0;
            .dropdowns {
                display: flex;
                align-items: center;
                > div {
                    margin-left: 8px;
                }
            }
            :global {
                .searchTextInputWrapper {
                    float: left;
                    height: 36px;
                    width: 180px;
                    box-sizing: border-box;
                    .searchTextInput {
                        float: left;
                        width: calc(180px - 12px - 40px);
                        height: 36;
                        line-height: 36px;
                        margin-top: 0;
                        padding-left: 0;
                    }
                    > img {
                        margin-top: 8px;
                        float: left;
                    }
                }
            }
        }
        > img {
            width: 70px;
            height: 60px;
            margin-left: 10px;
        }
        .noContent {
            padding: 16px;
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h1,
            p {
                margin: 0;
                padding: 0;
            }
            h1 {
                margin-top: 16px;
            }
            p {
                margin-top: 6px;
            }
        }
        > ul {
            list-style: none;
            padding-inline-start: 0;
            margin-block-start: 0;
            margin-block-end: 0;
            overflow: auto;
            overflow-x: hidden;
            flex: 1;
            > li {
                transition: transform 300ms ease-out, opacity 300ms ease-out;
                display: flex;
                align-items: flex-start;
                padding: 0 16px;
                font-size: 14px;
                color: $title;
                line-height: 1.2;
                &.disabled {
                    pointer-events: none;
                    opacity: 0.8;
                }
                button {
                    border: none;
                    background: none;
                    cursor: pointer;
                    margin-top: 7px;
                }
                :global {
                    .context-menu {
                        margin-top: 2px !important;
                        margin-left: 8px;
                        min-width: unset !important;
                    }
                }
                .hoverActions {
                    display: none;
                    overflow: visible;
                    svg {
                        height: 22px;
                        width: 22px;
                    }
                }
                // &:hover {
                //     .hoverActions {
                //         display: unset;
                //     }
                // }
                .content {
                    flex: 1;
                    padding: 12px 0;
                    .text {
                        position: relative;
                        cursor: text;
                        > span {
                            position: relative;
                            z-index: 1;
                            outline: none;
                            white-space: pre-wrap;
                            display: inline-block;
                        }
                    }
                    .hoverContainer {
                        position: absolute;
                        left: -6px;
                        right: -6px;
                        top: -4px;
                        bottom: -4px;
                        z-index: 0;
                        pointer-events: none;
                    }
                    &:hover {
                        .hoverContainer {
                            border: 1px dashed $border;
                            border-radius: 5px;
                        }
                    }
                }
                .due {
                    color: $steel;
                    display: flex;
                    align-items: center;
                    font-size: 11px;
                    margin-top: 6px;
                    svg {
                        height: 14px;
                        width: 14px;
                        margin-right: 4px;
                    }
                    &.today {
                        color: $dark-sky-blue;
                        svg {
                            color: $dark-sky-blue;
                        }
                    }
                    &.overdue {
                        color: $carnation-pink;
                        svg {
                            color: $carnation-pink;
                        }
                    }
                    &.tomorrow {
                        color: $orangey-yellow;
                        svg {
                            color: $orangey-yellow;
                        }
                    }
                }

                .checkbox {
                    flex-shrink: 0;
                    margin-top: 12px;
                    background-color: $list-header-bg;
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    margin-right: 16px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > svg {
                        height: 12px;
                        width: 12px;
                        color: white;
                        display: none;
                    }
                    &.done {
                        background-color: $dark-sky-blue;
                        > svg {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
