@import '../colors.scss';
@import '../general.scss';

#toggleableContainer {
    border-bottom: 1px solid #eee;
    padding: 16px 0;
    :global {
        #dropdown-tabs {
            position: unset !important;
            top: unset !important;
            z-index: 1 !important;
            margin-bottom: 8px;
        }
    }
    // &.open {
    //     border-bottom: 1px solid $steel;
    // }
    .titleContainer {
        @include notSelectable;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        height: 20px;
        &:hover {
            opacity: 0.6;
        }
        .left {
            display: flex;
            align-items: center;
            flex: 1;
            h2 {
                padding: 0 !important;
                margin: 0 !important;
                font-size: 14px;
            }
            svg {
                color: $dark;
                height: 20px;
                width: 20px;
                margin-left: 2px;
            }
            .tooltip {
                color: #aaa;
                margin-left: 8px;
                height: 16px;
                width: 16px;
            }
        }
        .right {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .custom {
                display: flex;
                align-items: center;
                margin-right: 16px;
                .rightComponent {
                    margin-left: 8px;
                }
            }
        }
    }
    .container {
        padding-top: 10px;
    }
}
