#invoiceLogList {
    flex: 1;
    .filterContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        z-index: 2;
        position: relative;
        .multiselect {
            width: 220px;
            max-width: 220px;
            height: 49px !important;
        }
        :global {
            .advancedSearch {
                flex-shrink: 0;
            }
            .tagsplitter {
                flex-shrink: 0;
            }
            .listFilterOutlinedField {
                height: 100%;
                width: 220px;
                > div {
                    height: 100%;
                }
            }
        }
        .dateRange {
            width: 220px;
            margin-right: 10px;
            height: 49px;
        }
        div[id^='select-'] {
            padding-top: 25px;
        }
    }
}
